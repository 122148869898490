export default function LoadingItems() {
    return (
        <div className="w-full">
            <div className="animate-pulse flex flex-col gap-4">
                <div className="flex flex-col w-full gap-4">
                    <div className="h-4 bg-slate-200 rounded w-full"></div>
                    <div className="h-4 bg-slate-200 rounded w-full"></div>
                    <div className="h-4 bg-slate-200 rounded w-full"></div>
                </div>
            </div>
        </div>
    )
}
import ContractIntervals from "./ContractIntervals"
import Contracts from "./Contracts"
import PayrollCover from "./PayrollCover"
import ImssCover from "./ImssCover"
import { useState, useEffect } from "react"
export default function ContractsTab(props){
    const { optionKey, enableTab, tabSelected } = props
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(tabSelected === optionKey)
    }, [enableTab, tabSelected])
    const objProps = {...props, ...{ active }}
    return(
        <div className="flex flex-col gap-8">
            <Contracts {...objProps} />
            <ContractIntervals {...objProps} />
            <PayrollCover {...objProps} />
            <ImssCover {...objProps} />
        </div>
    )
}
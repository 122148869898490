import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import { toast } from "react-hot-toast"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import dateFormat from "../../../../../custom_hooks/dateFormat"
export default function PersonalInformation(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [employeeInfo, setEmployeeInfo] = useState(null)
    const [employeePosition, setEmployeePosition] = useState()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [isNew, setIsNew] = useState(false)
    
    useEffect(() => {
        if (employee && active && loadingComponent) {
            //Obtenemos detallado de empleado
            getEmployee()
        }
    }, [employee, active])

    const getEmployee = () => {
        setLoadingComponent(true)        
        EmployeesService.getEmployee(employee)
            .then(response => {
                if (response.status === 200) {
                    setEmployeeInfo(response.data.data.employee)
                    setFormData(response.data.data.employee)  
                    setLoadingComponent(false)                  
                }
            })
    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            //Si anteriormente fue nuevo y tiene algo en employeePosition
            if (isNew && employeePosition) {
                setIsNew(false)
                setFormData(employeePosition)
            }
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "hireDate": dateFormat(data?.hireDate ?? new Date()),
            "employeeNumber": data?.employeeNumber ?? "",
            "name": data?.name ?? "",
            "lastName": data?.lastName ?? "",
            "secondSurname": data?.secondSurname ?? "",
            "email": data?.email ?? "",
            "businessEmail": data?.businessEmail ?? "",
            "businessPhone": data?.businessPhone ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.updateEmployee(employee, data)
            .then(response => {
                if ([200,201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployee()
                    toast.success("Se actualizó la información correctamente")
                }
                else{
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return (
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Información del empleado
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} showNew={false} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="hireDate"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="hireDate"
                                                            active={activateForm}
                                                            label="Fecha de contratación"
                                                            type="date"
                                                            onChange={onChange}
                                                            defaultValue={value}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="employeeNumber"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="employeeNumber"
                                                            active={activateForm}
                                                            label="Número de empleado"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="name"
                                                            active={activateForm}
                                                            label="Nombre(s)"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />
                                                {errors.name && <FieldRequiredError />}
                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="lastName"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="lastName"
                                                            active={activateForm}
                                                            label="Primer apellido"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />
                                                {errors.lastName && <FieldRequiredError />}
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="secondSurname"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="secondSurname"
                                                            active={activateForm}
                                                            label="Segundo apellido"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />

                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="email"
                                                            active={activateForm}
                                                            label="Correo electrónico personal"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="businessEmail"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="businessEmail"
                                                            active={activateForm}
                                                            label="Correo electrónico corporativo"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="businessPhone"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="businessPhone"
                                                            active={activateForm}
                                                            label="Teléfono corporativo"
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value ?? ""}
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </div>
                                        <FormDefaultActions
                                            activateForm={activateForm}
                                            handleCancel={handleCancel}
                                            loading={loading}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
import axios from "axios"
import Cookies from "js-cookie"
const API_URL = process.env.REACT_APP_API_URL
const login = (email, password) => {
    return axios
        .post(`${API_URL}/auth/login`, {
            email,
            password,
        })
        .then(response => {
            if (response.status === 200) {
                const { data } = response.data
                Cookies.set("apiToken", data.token, { expires: 1 })
                const { userInfo } = data
                const { name, lastName, permissions } = userInfo
                localStorage.setItem("userInfo", JSON.stringify({ name, lastName}))
                localStorage.setItem("userPermissions", JSON.stringify(data.userInfo.permissions))
            }
            return response
        })
        .catch(error => {
            return error.response
        })
}

const logout = () => {
    Cookies.remove("apiToken")
    localStorage.removeItem("userInfo")
    localStorage.removeItem("legalEntities")
    localStorage.removeItem("legalEntitySelected")
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("userInfo"))
}

const getCurrentUserPermissions = () => {
    return JSON.parse(localStorage.getItem("userPermissions"))
}

const getCurrentToken = () => {
    return Cookies.get('apiToken')
}

const isLoggedIn = () => {
    if (getCurrentToken()) {
        return true
    }
    return false
}

export default {
    login,
    logout,
    isLoggedIn,
    getCurrentUser,
    getCurrentUserPermissions
};
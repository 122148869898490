import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Container from "../Container"
import AreaForm from "./AreaForm"
import AreasService from "../../../services/areas.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function AreaDetail(props) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isNew, setIsNew] = useState(false)
    const [area, setArea] = useState({
        id: undefined,
        code: '',
        description: '',
        status: true
    })
    const [loading, setLoading] = useState(false)
    const onSubmit = (props) => {
        setLoading(true)
        if (isNew) {
            props.id = props.id !== "" ? props.id : undefined
            AreasService.createArea(props)
                .then(response => {                    
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                        setArea(response.data.data)
                        navigate("/areas")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
        else {
            AreasService.updateArea(props)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            if (id === undefined) {
                setIsNew(true)
            }
            else {
                AreasService.getArea(id)
                    .then(response => {
                        if (response.status === 200) {
                            setArea(response.data.data.area)
                        }
                    })

            }
        }
        return () => (isSubscribed = false)
    }, [])

    return (
        <>
            <Container>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Área</h1>
                </div>
                <div className="pt-2">
                    {
                        isNew ?
                            <p>Crear nueva área</p>
                            :
                            <p>Detalle área</p>
                    }
                </div>
                <div className="pt-8 w-full">
                    <div className="bg-white shadow-lg h-auto w-full md:w-4/6 lg:w-3/6 p-8 rounded-lg">
                        <AreaForm id={id} area={area} submit={onSubmit} loading={loading} />
                    </div>
                </div>
            </Container>            
        </>

    )
}
import { useEffect, useState } from "react"
import Spinner from "../../Spinner"
export default function CustomInput(props) {
    const { eKey = "", label, active = false, type = "text", onChange, options = [], defaultValue = "", value = "", loading = false } = props
    const DISABLED_CLASS = "pointer-events-none p-0 border-none bg-none calendar-disable"
    const ACTIVE_CLASS = "placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
    return (
        <>
            <label htmlFor="" className="block mb-2 text-xs">{label}</label>
            <div className="relative">
            {
                type == "select" ?
                    <select
                        onChange={onChange}
                        value={value}
                        name={eKey}
                        id={eKey}
                        className={`w-full --transition-all --ease-out --duration-200 ${active ? ACTIVE_CLASS : DISABLED_CLASS}`}
                    >
                        <option value="" disabled>Seleccione una opción...</option>
                        {
                            options.map((item, index) => {
                                return <option key={`${eKey}_${index}`} value={item.value}>{item.label}</option>
                            })
                        }

                    </select>
                    :
                    <input
                        onChange={onChange}
                        value={value}
                        name={eKey}
                        id={eKey}
                        type={type}
                        className={`w-full --transition-all --ease-out --duration-200 ${active ? ACTIVE_CLASS : DISABLED_CLASS}`}
                    />
            }
            {
                loading &&
                <div className="absolute flex inset-0 justify-end items-center">
                    <div className="bg-white mr-2"><Spinner /></div>
                </div>
            }
            </div>
        </>
    )
}
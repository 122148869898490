import Container from "../Container"
import { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import EmployeesService from "../../../services/employees.service"
import Table from "../Table"
import { MdPeople, MdEdit, MdPersonAdd } from "react-icons/md"
import ReactTable from "../../ReactTable"
import StatusBadge from "../../Utils/StatusBadge"
import Modal from "../../Modal/Modal"
import NewEmployeeForm from "./NewEmployeeForm"
import NewEmployee from "./NewEmployee"
import AppContext from "../../../context/AppContext"
export default function Empleados() {
    const navigate = useNavigate()
    const { legalEntitySelected } = useContext(AppContext)
    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(true)
    const [tab, setTab] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [reloadData, setReloadData] = useState(false)
    const columns = [
        {
            header: 'Nombre',
            accessorKey: 'name',
            cell: ({ row }) => {
                return `${row.original.name} ${row.original?.lastName} ${row.original?.secondSurname}`
            }
        },
        {
            header: 'Puesto',
            accessorKey: 'position',
            cell: ({ row }) => {
                const { positions, legalEntities } = row.original
                return (
                    <div>
                        <p className="text-xs mb-2">{positions[0]?.name}</p>
                        <span className={`inline-flex items-center py-1 px-2 mr-2 text-xs font-medium rounded-full text-indigo-800 bg-indigo-100`}>
                            <strong>{legalEntities[0]?.name}</strong>
                        </span>
                    </div>
                )
            }
        },
        {
            header: 'Status',
            accessorKey: 'statusBadge',
            cell: ({ row }) => {
                return <StatusBadge status={row.original.status} />
            }
        },
        {
            header: '',
            accessorKey: 'details',
            cell: ({ row }) => {
                return <a className="text-indigo-800 hover:text-indigo-500 font-bold hover:underline" href={`/empleados/${row.original.uuid}`}>
                    <MdEdit className="w-5 h-5" />
                </a>
            }
        }
    ]
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            if (legalEntitySelected.key !== 0) getEmployees()
        }
        return () => (isSubscribed = false)
    }, [legalEntitySelected])

    useEffect(() => {
        if (reloadData) getEmployees()
    }, [reloadData])

    const getEmployees = () => {
        setLoading(true)
        let params = { 'legalentity': legalEntitySelected.key }
        EmployeesService.getEmployees(params)
            .then(response => {
                if (response.status === 200) {
                    setEmployees(response.data.data.employees)
                    setLoading(false)
                }
            })
    }

    const handleNewEmployee = () => {
        setReloadData(false)
        setShowModal(true)
    }

    return (
        <>
            <Container navLocation={"empleados"}>
                <div className="bg-white">
                    <div className="bg-indigo-700 text-white rounded-t-md drop-shadow-md">
                        <div className="flex items-center gap-4 pt-4 pb-2 px-8 border-b border-indigo-500">
                            <div>
                                <span><MdPeople className="w-8 h-8" /></span>
                            </div>
                            <div>
                                <p className="text-xl font-bold">Empleados</p>
                                <p className="text-sm">Lista de empleados -propiedad-</p>
                            </div>
                        </div>
                        <div className="px-8 py-2 flex justify-between">
                            <div className="flex gap-4">
                                <button className="border bg-indigo-500 border-indigo-800 hover:bg-indigo-500 p-2 rounded-md font-bold text-sm">
                                    Empleados
                                </button>
                                <button disabled className="cursor-not-allowed pointer-events-none hover:bg-indigo-500 p-2 rounded-md font-bold text-sm ">
                                    Equipos
                                </button>
                            </div>
                            <div className="flex flex-end">
                                <button
                                    className="flex items-center gap-2 py-2 px-4 bg-indigo-700 border border-indigo-700 hover:bg-indigo-500 text-white rounded-md font-bold"
                                    onClick={() => handleNewEmployee()}
                                >
                                    Nuevo <MdPersonAdd className="h-6 w-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="p-8">
                        <div>
                            <ReactTable
                                filterKeys={['name', 'lastName', 'secondSurname', 'email']}
                                tableColumns={columns} 
                                tableItems={employees} 
                                loading={loading} />
                        </div>
                    </div>

                </div>
                <NewEmployee showModal={showModal} setShowModal={setShowModal} setReloadData={setReloadData} />
            </Container>
        </>
    )
}
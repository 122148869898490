import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getEmployees = (params) => {
  return axios.get(`${API_URL}/employees`, { params })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployee = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createEmployee = (data) => {
  return axios.post(`${API_URL}/employees`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateEmployee = (employee, data) => {
  return axios.put(`${API_URL}/employees/${employee}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeDetail = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/detail?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeDetail = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/detail`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeePosition = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/position?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeePosition = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/position`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeePositions = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/positions`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeLocation = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/location?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeLocation = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/location`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeBirthplace = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/birthplace?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeBirthplace = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/birthplace`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeAddress = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/address?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeAddress = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/address`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeContract = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/contract?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeContracts = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/contracts`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeContractInterval = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/contract-interval`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeContractInterval = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/contract-interval`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeePayrollCover = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/payroll-cover?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeePayrollCover = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/payroll-cover`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeImssCover = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/imss-cover`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeImssCover = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/imss-cover`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeSalaryCover = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/salary-cover`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeSalaryCover = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/salary-cover`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeBankInformation = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/bank-information?usecatalogs=true`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeBankInformation = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/bank-information`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getEmployeeStatusNotice = (employee) => {
  return axios.get(`${API_URL}/employees/${employee}/status-notice`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const manageEmployeeStatusNotice = (employee, data) => {
  return axios.post(`${API_URL}/employees/${employee}/status-notice`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getEmployees,
  getEmployee,
  createEmployee,
  updateEmployee,
  getEmployeeDetail,
  manageEmployeeDetail,
  getEmployeePosition,
  getEmployeePositions,
  manageEmployeePosition,
  getEmployeeLocation,
  manageEmployeeLocation,
  getEmployeeBirthplace,
  manageEmployeeBirthplace,
  getEmployeeAddress,
  manageEmployeeAddress,
  getEmployeeContract,
  manageEmployeeContracts,
  getEmployeeContractInterval,
  manageEmployeeContractInterval,
  getEmployeePayrollCover,
  manageEmployeePayrollCover,
  getEmployeeImssCover,
  manageEmployeeImssCover,
  getEmployeeSalaryCover,
  manageEmployeeSalaryCover,
  getEmployeeBankInformation,
  manageEmployeeBankInformation,
  getEmployeeStatusNotice,
  manageEmployeeStatusNotice
}
import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import { toast } from "react-hot-toast"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import { MdNavigateNext } from "react-icons/md"
export default function PayrollCover(props){
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [employeePayrollCover, setEmployeePayrollCover] = useState(null)

    const [employeeTypesSelector, setEmployeeTypesSelector] = useState([])
    const [paymentBasesSelector, setPaymentBasesSelector] = useState([])
    const [workShiftsSelector, setWorkShiftsSelector] = useState([])
    const [salaryZonesSelector, setSalaryZonesSelector] = useState([])
    const [regimeTypesSelector, setRegimeTypesSelector] = useState([])
    const [payrollTypesSelector, setPayrollTypesSelector] = useState([])
    const [jobRisksSelector, setJobRisksSelector] = useState([])
    const [contributionBasesSelector, setContributionBasesSelector] = useState([])
    const [paymentPeriodicitiesSelector, setPaymentPeriodicitiesSelector] = useState([])
    const [paymentMethodsSelector, setPaymentMethodsSelector] = useState([])    

    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeePayrollCover()
        }
    }, [employee, active])

    const getEmployeePayrollCover = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeePayrollCover(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }

    const formatData = (data) => {
        const { payrollCover, catalogs } = data
        setEmployeePayrollCover(payrollCover)
        setFormData(payrollCover)
        formatCatalogs(catalogs) 
    }

    const formatCatalogs = (catalogs) => {
        const { 
            employeeTypes, paymentBases, workShifts, salaryZones, regimeTypes, 
            payrollTypes, paymentPeriodicities, paymentMethods, jobRisks, contributionBases
        } = catalogs
        setEmployeeTypesSelector(employeeTypes.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setPaymentBasesSelector(paymentBases.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setWorkShiftsSelector(workShifts.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setSalaryZonesSelector(salaryZones.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setRegimeTypesSelector(regimeTypes.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setPayrollTypesSelector(payrollTypes.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setPaymentPeriodicitiesSelector(paymentPeriodicities.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setPaymentMethodsSelector(paymentMethods.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setJobRisksSelector(jobRisks.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))
        setContributionBasesSelector(contributionBases.map((item) => ({ value: item.id, label: `${item.code} - ${item.name}` })))

    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            setFormData(employeePayrollCover)
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = async (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "employeeType":  data?.employeeTypeId ?? "",
            "paymentBase":  data?.paymentBaseId ?? "",
            "workShift": data?.workShiftId ?? "",
            "salaryZone": data?.salaryZoneId ?? "",
            "regimeType": data?.regimeTypeId ?? "",
            "payrollType": data?.payrollTypeId ?? "",
            "paymentPeriodicity": data?.paymentPeriodicityId ?? "",
            "paymentMethod": data?.paymentMethodId ?? "",
            "jobRisk": data?.jobRiskId ?? "",
            "contributionBase": data?.contributionBaseId ?? "",
            "afore": data?.afore ?? "",
            "infonavitNumber": data?.infonavitNumber ?? "",
            "fonacotNumber": data?.fonacotNumber ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeePayrollCover(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployeePayrollCover()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }
    return(
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Carátula informativa de nómina
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} showNew={false} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="employeeType"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="employeeType"
                                                                type="select"
                                                                options={employeeTypesSelector}
                                                                active={activateForm}
                                                                label="Tipo de empleado"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="paymentBase"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="paymentBase"
                                                                type="select"
                                                                options={paymentBasesSelector}
                                                                active={activateForm}
                                                                label="Base de pago"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="workShift"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="workShift"
                                                                type="select"
                                                                options={workShiftsSelector}
                                                                active={activateForm}
                                                                label="Tipo de jornada laboral"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="salaryZone"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="salaryZone"
                                                                type="select"
                                                                options={salaryZonesSelector}
                                                                active={activateForm}
                                                                label="Zona de salario"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="regimeType"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="regimeType"
                                                                type="select"
                                                                options={regimeTypesSelector}
                                                                active={activateForm}
                                                                label="Régimen de contratación"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="payrollType"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="payrollType"
                                                                type="select"
                                                                options={payrollTypesSelector}
                                                                active={activateForm}
                                                                label="Tipo de nómina"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="jobRisk"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="jobRisk"
                                                                type="select"
                                                                options={jobRisksSelector}
                                                                active={activateForm}
                                                                label="Riesgo de puesto"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="contributionBase"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="contributionBase"
                                                                type="select"
                                                                options={contributionBasesSelector}
                                                                active={activateForm}
                                                                label="Base de cotización"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="paymentPeriodicity"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="paymentPeriodicity"
                                                                type="select"
                                                                options={paymentPeriodicitiesSelector}
                                                                active={activateForm}
                                                                label="Periodicidad de pago"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="paymentMethod"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="paymentMethod"
                                                                type="select"
                                                                options={paymentMethodsSelector}
                                                                active={activateForm}
                                                                label="Método de pago"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="infonavitNumber"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="infonavitNumber"
                                                                active={activateForm}
                                                                label="Infonavit"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="afore"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="afore"
                                                                active={activateForm}
                                                                label="Afore"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="fonacotNumber"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="fonacotNumber"
                                                                active={activateForm}
                                                                label="Fonacot"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="hidden mt-8 flex justify-center border-t border-slate-200">
                                <button className="flex justify-center items-center pt-8 text-sm text-gray-400 hover:text-indigo-700">
                                    Ver historial de contratos <MdNavigateNext className="w-5 h-5"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
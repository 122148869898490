import { MdHelp, MdCloudDownload, MdRemoveRedEye, MdCalendarToday, MdPerson } from "react-icons/md"
import { format } from "date-fns"
import FileIcon from "./FileIcon"
import FileDownloader from "./FileDownloader"
export default function FileVersionItem(props) {
    const { file } = props
    const handleFilePreview = (event) => {
        event.stopPropagation()
        alert("File Preview")
    }

    const handleFileDownload = (event) => {
        event.stopPropagation()
        alert("File Download")
    }

    return (
        <div
            className="bg-white drop-shadow-md border rounded-md p-4 flex items-center gap-4 mb-4"
        >
            <div>
                <FileIcon size="xs" fileType={file.type} />
            </div>
            <div className="flex-1 w-4/6">
                <div className="flex flex-1-- flex-col gap-1">
                    <FileDownloader fileKey={file.key} uuid={file.uuid}>
                        <p
                            className="text-xs text-blue-500 hover:underline hover:cursor-pointer truncate text-ellipsis">
                            {file?.fileName}
                        </p>
                    </FileDownloader>
                    <div className="flex flex-1 flex-col overflow-hidden">
                        <p className="text-[8px] flex gap-1 items-center truncate hyphens-auto">
                            <MdPerson className="w-2.5 h-2.5" />{file.createdBy}
                        </p>
                        <p className="text-[8px] flex gap-1 items-center">
                            <MdCalendarToday className="w-2.5 h-2.5" />{format(new Date(file.createdAt), 'dd-MM-yyyy')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
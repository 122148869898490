import { useState, useContext } from "react"
import AppContext from "../../../context/AppContext"
import LegalEntitiesSelector from "./LegalEntitiesSelector"
import RequirePermission from "../../../auth/RequirePermission"
export default function Header() {
    const { showNav, handleShowNav } = useContext(AppContext)
    return (
        <div className="fixed md:relative z-[98] w-full">
            <div className="bg-white shadow w-full h-[80px] flex">
                <header className="container flex flex-row items-center justify-end box-border">
                    <RequirePermission allowedRoles={['rrhh_legal_entities_selector']}>
                        <LegalEntitiesSelector />    
                    </RequirePermission>                  
                </header>
            </div>
        </div>
    )
}
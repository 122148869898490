import { useEffect, useState } from "react";
import {
    MdArrowForward
  } from "react-icons/md";
  import { FaChevronCircleDown } from "react-icons/fa";
import RequirePermission from "../../../auth/RequirePermission";
export default function MenuItem(props) {
  const {
    handleOptionSelected,
    enableOption,
    optionSelected,
    optionKey,
    requirePermission = false,
    allowedRoles = [],
    options = [],
    label,
    banner,
    icon = null,
    navLocation,
    href = null,
  } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [isCurrentNav, setIsCurrentNav] = useState(false);
  useEffect(() => {
    optionSelected === optionKey && enableOption
      ? setShowOptions(true)
      : setShowOptions(false);
  }, [enableOption, optionSelected]);
  useEffect(() => {
    setIsCurrentNav(optionKey === navLocation);
  }, [navLocation]);
  return (
    <li>
      <RequirePermission requirePermission={requirePermission} allowedRoles={allowedRoles} >
        {href ? (
          <a
            href={href}
            className={`w-full text-[14px] p-2 hover:bg-indigo-700 hover:text-white rounded-md font-bold flex items-center justify-between ${
              showOptions || isCurrentNav ? "bg-indigo-700 text-white" : ""
            } ${showOptions ? "rounded-t-md rounded-b-none" : ""}`}
          >
            <span className="flex gap-2 items-center">
              {icon} {label}
            </span>
          </a>
        ) : (
          <button
            typeof="button"
            onClick={() => handleOptionSelected(!enableOption, optionKey)}
            className={`w-full text-[14px] p-2 hover:bg-indigo-700 hover:text-white rounded-md font-bold flex items-center justify-between ${
              showOptions || isCurrentNav ? "bg-indigo-700 text-white" : ""
            } ${showOptions ? "rounded-t-md rounded-b-none" : ""}`}
          >
            <span className="flex gap-2 items-center">
              {icon} {label}
            </span>
            <FaChevronCircleDown
              className={`transition-all ease-out duration-300 ${
                showOptions ? "rotate-180" : ""
              }`}
            />
          </button>
        )}
      </RequirePermission>
      {options.length > 0 && (
        <div
          className={`${
            showOptions ? "" : "!hidden"
          } bg-altern text-default left-0 right-0 w-full rounded-b-md shadow-md overflow-hidden`}
        >
          <ul className="flex flex-col gap-4 w-full lg:w-fit p-4">
            {options.map((option, index) => {
              const OptionComponent = () => {
                return (
                  <li
                    className={`font-medium flex items-stretch text-[14px] ${
                      option.hidden ? "hidden" : ""
                    }`}
                  >
                    <a
                      href={option.href}
                      className="w-full flex items-center gap-2 group hover:text-indigo-700"
                    >
                      {option.label}{" "}
                      <MdArrowForward className="group-hover:opacity-100 opacity-0" />
                    </a>
                  </li>
                );
              };
              return option.requirePermission ? (
                <RequirePermission
                  key={`itemOption_${optionKey}_${index}`}
                  allowedRoles={option.allowedRoles}
                >
                  <OptionComponent />
                </RequirePermission>
              ) : (
                <OptionComponent key={`itemOption_${optionKey}_${index}`} />
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
}

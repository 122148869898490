import { useState } from "react"
import { MdMoreVert } from "react-icons/md"
export default function FormOptions(props) {
    const { handleAction, showNew = true } = props
    const [showOptions, setShowOptions] = useState(false)
    const handleOption = (action) => {
        setShowOptions(false)
        handleAction(action)
    }
    return (
        <div className="relative">
            <button onClick={() => setShowOptions(!showOptions)} className="p-0.5 hover:bg-slate-200 rounded-md">
                <MdMoreVert className="w-6 h-6" />
            </button>
            <div className={`absolute z-[98] right-0 ${showOptions ? '' : 'hidden'}`}>
                <ul className="bg-white rounded-md p-2 border border-slate-200 drop-shadow-md text-sm">
                    <li>
                        <button
                            className="hover:bg-slate-100 p-1 w-full rounded-md"
                            onClick={() => handleOption({ action: 'edit' })}
                        >
                            Editar
                        </button>
                    </li>
                    {
                        showNew &&
                        <li>
                            <button
                                className="hover:bg-slate-100 p-1 w-full rounded-md"
                                onClick={() => handleOption({ action: 'new' })}
                            >
                                Nuevo
                            </button>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}
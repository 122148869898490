import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getCandidates = () => {
    return axios.get(`${API_URL}/candidates`)
      .then(response => {
        return response
      })
      .catch(error => {
        return error.response
      })
  }

  const updateCandidate = (data) => {
    return axios.put(`${API_URL}/candidates/${data.id}`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        return error.response
      })
  }

  const downloadCVCandidate = (file) => {
    return axios.get(`${API_URL}/candidates/downloadcv/${file}`, {responseType: 'arraybuffer'})
      .then(response => {
        return response
      })
      .catch(error => {
        return error.response
      })
  }

  export default {
    getCandidates,
    updateCandidate,
    downloadCVCandidate
  }
import { useEffect, useState } from "react"
export default function TabContent(props) {
    const { optionKey, enableTab, tabSelected } = props
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(tabSelected === optionKey)
    }, [enableTab, tabSelected])
    return (
        <>
            {
                <div className={active ? '' : 'hidden'}>
                    {props.children}
                </div>
            }
        </>
    )
}
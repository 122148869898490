import CustomInput from "../../CustomInput"
import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import EmployeesService from "../../../../../services/employees.service"
import FormOptions from "../../../FormOptions"
import LoadingCard from "../../../../Utils/LoadingCard"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import { toast } from "react-hot-toast"
export default function Locations(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, control } = useForm()
    const [employeePosition, setEmployeePosition] = useState()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [employeeLocation, setEmployeeLocation] = useState({})
    const [properties, setProperties] = useState([])
    const [legalEntities, setLegalEntities] = useState([])
    const [isNew, setIsNew] = useState(false)

    useEffect(() => {        
        if(employee && active && loadingComponent){
            getEmployeeLocation()
        }
    }, [employee, active ])

    const getEmployeeLocation = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeeLocation(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }

    const formatData = (data) => {
        const { location = {}, catalogs = {} } = data
        setEmployeeLocation(location)
        setLegalEntities(catalogs.legalEntities.map((item) => ({ value: item.id, label: item.name })))
        setProperties(catalogs.properties.map((item) => ({ value: item.id, label: item.description })))
        setFormData(location)
    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            if (isNew && employeeLocation) {
                setIsNew(false)
                setFormData(employeeLocation)
            }
            else {
                setFormData(employeeLocation)
            }
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "legalEntity": data?.legalEntityId ?? "",
            "property": data?.propertyId ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeLocation(employee, data)
            .then(response => {
                if ([200,201].includes(response.status)) {
                    setActivateForm(false)
                    setIsNew(false)
                    getEmployeeLocation()
                    toast.success("Se actualizó la información correctamente")
                }
                else{
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return (
        <div className="mt-8">
            {
                !loadingComponent ?
                    <div>
                        <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                            <div className="p-8">
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col gap-1">
                                        <p className="font-medium block">
                                            Locación del empleado
                                        </p>
                                        <div className="w-8 h-0.5 bg-indigo-700"></div>
                                    </div>
                                    <FormOptions handleAction={handleFormOptionAction} />
                                </div>
                                <div className="mt-8">
                                    <form onSubmit={handleSubmit(submit)}>
                                        <input
                                            hidden
                                            {...register("entityId")}
                                        />
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="legalEntity"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="legalEntitySelector"
                                                                type="select"
                                                                options={legalEntities}
                                                                value={value}
                                                                active={activateForm}
                                                                label="Entidad legal"
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.legalEntity && <FieldRequiredError />}
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="property"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="propertySelector"
                                                                type="select"
                                                                options={properties}
                                                                active={activateForm}
                                                                label="Propiedad"
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                    {errors.property && <FieldRequiredError />}
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
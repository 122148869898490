import { useState, useContext } from "react"
import { useForm } from "react-hook-form"
import DotsLoading from "../../DotsLoading"
import ModalContext from "../../../context/ModalContext"
import EmployeesService from "../../../services/employees.service"
import { useNavigate } from "react-router-dom"
import { toast } from "react-hot-toast"
import AppContext from "../../../context/AppContext"
export default function NewEmployeeForm(props) {
    const { setReloadData } = props
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm()
    const [errorMessage, setErrorMessage] = useState({ show: false, message: 'Error al procesar la solicitud' })
    const { handleShowModal } = useContext(ModalContext)
    const { legalEntitySelected } = useContext(AppContext)
    const submit = (data) => {
        setLoading(true)
        const params = { ...data, ...{ legalEntity: legalEntitySelected.key } }
        EmployeesService.createEmployee(params)
            .then(response => {
                const { error = "Ocurrió un error al actualizar la información" } = response.data
                if (response.status === 201) {
                    handleShowModal(false)
                    toast.success("Se actualizó la información correctamente")
                    setReloadData(true)
                }
                else {
                    toast.error(error)
                }
                setLoading(false)
            })

    }

    return (
        <div>
            <div className="mb-4 flex justify-center">
                <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full text-indigo-800 bg-indigo-100`}>
                    <strong>{legalEntitySelected.label}</strong>
                </span>
            </div>
            <form onSubmit={handleSubmit(submit)}>
                <div className="relative flex flex-col gap-4 pb-4">
                    <div className="w-full">
                        <label htmlFor="" className="block mb-2 text-sm font-medium">Nombre(s)</label>
                        <input
                            type="text"
                            className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                            {...register("name", { required: "Campo requerido" })}
                        />
                        <p className="text-red-800 text-xs pt-1">{errors.name?.message}</p>
                    </div>
                    <div className="w-full grid grid-cols-2 gap-4">
                        <div >
                            <label htmlFor="" className="block mb-2 text-sm font-medium">Primer apellido</label>
                            <input
                                type="text"
                                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                                {...register("lastName", { required: "Campo requerido" })}
                            />
                            <p className="text-red-800 text-xs pt-1">{errors.email?.message}</p>
                        </div>
                        <div>
                            <label htmlFor="" className="block mb-2 text-sm font-medium">Segundo apellido</label>
                            <input
                                type="text"
                                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                                {...register("secondSurname")}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <label htmlFor="" className="block mb-2 text-sm font-medium">Correo</label>
                        <input
                            type="email"
                            className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                            {...register("email", { required: "Campo requerido" })}
                        />
                        <p className="text-red-800 text-xs pt-1">{errors.email?.message}</p>
                    </div>
                    <div className="w-full">
                        <label htmlFor="" className="block mb-2 text-sm font-medium">Fecha de contratación</label>
                        <input
                            type="date"
                            className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                            {...register("hireDate", { required: "Campo requerido" })}
                        />
                        <p className="text-red-800 text-xs pt-1">{errors.hireDate?.message}</p>
                    </div>
                </div>
                <p className="text-red-800 text-center pt-2 text-sm">{errorMessage.show ? errorMessage.message : ''}</p>
                <div className="pt-4 flex gap-4 md:gap-8">
                    <button
                        onClick={() => handleShowModal(false)}
                        type="button"
                        className="transition-all w-full p-2 rounded-md bg-slate-200 font-bold hover:bg-slate-100">
                        Cancelar
                    </button>
                    <button type="submit" className={`transition-all w-full text-white bg-indigo-700 hover:bg-indigo-500 p-2 rounded-md font-bold flex justify-center`} disabled={loading ? true : false}>
                        {
                            loading ?
                                <DotsLoading />
                                :
                                <span>Guardar</span>
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}
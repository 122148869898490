import Container from "../Container"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PropertiesService from "../../../services/properties.service"
import Table from "../Table"
export default function Properties() {
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true)
    const tableColumns = [
        {
            Header: 'Código',
            accessor: 'code', // accessor is the "key" in the data
        },
        {
            Header: 'Descripción',
            accessor: 'description',
        },
        {
            Header: 'Status',
            accessor: 'statusBadge',
        }
    ]
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            PropertiesService.getProperties()
                .then(response => {
                    if (response.status === 200) {
                        formatData(response.data.data.properties)
                        setLoading(false)
                    }
                })
        }
        return () => (isSubscribed = false)
    }, [])

    const formatData = (data) => {
        data.map(function (item) {
            item.statusBadge = <Badge status={item.status} />
            return item
        })
        setProperties(data)
    }
    function Badge({ status }) {
        let label = status ? 'Activo' : 'Inactivo'
        return (
            <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full ${status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}`}>
                {label}
            </span>
        )
    }
    return (
        <>
            <Container navLocation={"empresa"}>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Propiedades</h1>
                </div>
                <div className="pt-8">
                    <Table tableColumns={tableColumns} tableItems={properties} loading={loading} />                   
                </div>
            </Container>
        </>
    )
}
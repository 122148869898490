import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import { format } from 'date-fns'
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import { toast } from "react-hot-toast"
export default function Address(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [federalEntitiesSelector, setFederalEntitiesSelector] = useState([])

    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeeAddress()
        }
    }, [employee, active])

    const getEmployeeAddress = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeeAddress(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }
    const formatData = (data) => {
        const { catalogs, employeeAddress } = data
        formatCatalogs(catalogs)
        setFormData(employeeAddress)
    }

    const formatCatalogs = (catalogs) => {
        const { federalEntities  } = catalogs
        setFederalEntitiesSelector(federalEntities.map((item) => ({ value: item.id, label: item.name })))

    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            setFormData()
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "federalEntity": data?.federalEntityId ?? "",
            "municipality": data?.municipality ?? "",
            "city": data?.city ?? "",
            "postalCode": data?.postalCode ?? "",
            "address": data?.address ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeAddress(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployeeAddress()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return (
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Dirección
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} showNew={false} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="federalEntity"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="federalEntity"
                                                                type="select"
                                                                options={federalEntitiesSelector}
                                                                active={activateForm}
                                                                label="Estado"
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="municipality"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="municipality"
                                                                active={activateForm}
                                                                label="Municipio"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="city"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="city"
                                                                active={activateForm}
                                                                label="Ciudad"
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="postalCode"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="postalCode"
                                                                active={activateForm}
                                                                label="Código postal"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="address"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="address"
                                                                active={activateForm}
                                                                label="Dirección"
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
export default function LoadingCard() {
    return (
        <div className="border border-slate-300 drop-shadow-md bg-white rounded-md p-8 w-full">
            <div className="animate-pulse flex flex-col gap-4">
                <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="w-full space-y-6 py-1">
                    <div className="space-y-3">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import LegalentitiesService from "../services/legalentities.service";
export default function AppProvider(props) {
    const [navLocation, setNavLocation] = useState("")
    const [loadingData, setLoadingData] = useState(true)
    const [showNav, setShowNav] = useState(false)
    const [legalEntitySelected, setLegalEntitySelected] = useState({ key: 0, label: "Entidades legales"})
    const [legalEntities, setLegalEntities] = useState({
        loading: true,
        data: []
    })
    const handleShowNav = () => {
        setShowNav(!showNav)
    }
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("legalEntities"))
        const currentLE = JSON.parse(localStorage.getItem("legalEntitySelected"))
        if(!data) getLegalEntities()
        else setLegalEntities(data)
        if(currentLE) setLegalEntitySelected(currentLE)
    }, [])

    const getLegalEntities = () => {
        LegalentitiesService.getLegalEntities()
            .then(response => {
                if (response.status === 200) {
                    const legalEntitiesData = response.data.data.legalEntities
                    localStorage.setItem("legalEntities", JSON.stringify({ data: legalEntitiesData, loading: false}))
                    setLegalEntities({ data: legalEntitiesData, loading: false})
                    handleLegalEntitySelected(legalEntitiesData[0])
                }
            })
    }

    const handleLegalEntitySelected = (option) => {
        localStorage.setItem("legalEntitySelected", JSON.stringify({ key: option.uuid, label: option.name}))
        setLegalEntitySelected({ key: option.uuid, label: option.name})
    }

    return (
        <AppContext.Provider value={{ showNav, handleShowNav, navLocation, setNavLocation, legalEntities, legalEntitySelected, handleLegalEntitySelected}}>
            {props.children}
        </AppContext.Provider>
    )
}
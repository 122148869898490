import DotsLoading from "../DotsLoading"
export default function FormDefaultActions(props) {
    const { activateForm = false, handleCancel, loading = false } = props
    return (
        <div className={`${activateForm ? '' : 'hidden'} pt-4 flex gap-4 justify-end`}>
            <button
                onClick={() => handleCancel()}
                type="button"
                className="w-fit bg-slate-200 hover:bg-slate-300 py-2 px-4 rounded-md font-bold flex justify-center">
                Cancelar
            </button>
            <button
                type="submit"
                className={`transition-all w-fit min-w-[100px] text-white bg-indigo-700 hover:bg-indigo-500 py-2 px-4 rounded-md font-bold flex justify-center`}
                disabled={loading ? true : false}>
                {
                    loading ?
                        <DotsLoading />
                        :
                        <span>Guardar</span>
                }
            </button>
        </div>
    )
}
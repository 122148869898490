import FileVersionItem from "./FileVersionItem"
import { MdHelp, MdSync, MdBlurOn, MdClose, MdUpgrade, MdPerson, MdCalendarToday, MdCopyAll, MdSecurity } from "react-icons/md"
import { useState } from "react"
import { format } from "date-fns"
import NewFileForm from "./NewFileForm"
import FileIcon from "./FileIcon"
import FileDownloader from "./FileDownloader"
import { toast } from "react-hot-toast"
import FilesService from "../../../services/files.service"
export default function FilePreview(props) {
    const { directory = {}, masterFile = {}, handleClose, responseHandler, enableTools = false, frameView = false } = props
    const { files = [] } = masterFile
    const [showUpdateFileForm, setShowUpdateFileForm] = useState(false)
    const [showItemPermissionsForm, setShowItemPermissionsForm] = useState(false)
    const handleCopyClipBoard = (event, text) => {
        event.stopPropagation()
        navigator.clipboard.writeText(text).then(() => {
            toast.success(`Se copio la clave del archivo al portapapeles.`)
        }, () => {
            toast.error("Error al copiar al portapapeles.")
        })
    }
    return (
        <>
            <div
                className="flex w-full min-h-[50vh] max-h-[100vh]"
            >
                <div className="relative w-full bg-slate-100 pt-4 pb-8 px-4 rounded-md drop-shadow-md overflow-x-hidden overflow-y-auto">
                    {
                        !frameView &&
                        <div className="flex w-full justify-end">
                            <button
                                onClick={(e) => handleClose(false)}
                                className="flex justify-end hover:bg-indigo-200 p-2 rounded-md">
                                <MdClose className="w-6 h-6" />
                            </button>
                        </div>
                    }
                    <div className="mb-4">
                        <p className="text-center font-bold">Detalles del archivo</p>
                    </div>
                    {
                        files.length > 0 ?
                            <div className="">
                                <div className="flex flex-col gap-1 mb-4 break-words">
                                    <div className="flex justify-center mb-4">
                                        <FileIcon size="lg" fileType={files[0].type} />
                                    </div>
                                    <p className="font-bold text-center">{masterFile.name}</p>
                                    <FileDownloader fileKey={files[0].key} uuid={files[0].uuid}>
                                        <div className="w-full flex justify-center items-center">
                                            <p
                                                className="text-center text-sm font-bold text-blue-500 hover:underline hover:cursor-pointer">
                                                {files[0]?.fileName}
                                            </p>
                                        </div>
                                    </FileDownloader>
                                    {
                                        masterFile.description &&
                                        <p className="font-bold text-sm text-center pt-2">{masterFile.description}</p>
                                    }

                                    <div className="pt-4 flex flex-col justify-center items-center gap-2">
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdSync className="w-3 h-3" /><span>{format(new Date(files[0].createdAt), 'dd-MM-yyyy')}</span>
                                        </p>
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdPerson className="w-3 h-3" />{masterFile.createdBy}
                                        </p>
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdCalendarToday className="w-3 h-3" /><span>{format(new Date(masterFile.createdAt), 'dd-MM-yyyy')}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 py-4">
                                    <button
                                        onClick={() => setShowUpdateFileForm(true)}
                                        className="font-medium flex justify-center items-center gap-2 bg-indigo-600 hover:bg-indigo-800 text-white rounded-md drop-shadow-md w-full p-2">
                                        <MdUpgrade className="w-6 h-6" /> Actualizar archivo
                                    </button>
                                </div>
                                <div className="border-t"></div>
                                <div className="pt-4">
                                    <p className="font-bold text-sm mb-4">Historial de versiones</p>
                                    <div>
                                        {
                                            files.map((file, indexFilePrev) => {
                                                return <FileVersionItem key={`filePrev_${indexFilePrev}`} file={file} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="w-full flex flex-col gap-4 justify-center items-center">
                                <MdBlurOn className="w-[8rem] h-[8rem] text-slate-200" />
                                <div>
                                    <p className="text-slate-400 text-sm">Seleccione un elemento</p>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <NewFileForm
                formKey="updateFileForm"
                modalName={"Actualizar archivo"}
                actionLabel={"Actualizar"}
                showModal={showUpdateFileForm}
                setShowModal={setShowUpdateFileForm}
                targetDirectory={directory}
                targetMasterFile={masterFile.uuid}
                responseHandler={responseHandler}
            />
        </>
    )
}
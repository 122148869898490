import { useState, useEffect } from "react";
import EmployeeTeamsService from "../../../services/employeeteams.service";
import ReportsService from "../../../services/reports.service";
import EmployeeShiftWorkItem from "./EmployeeShiftWorkItem";
import { FaFileExcel } from "react-icons/fa";
import DotsLoading from "../../DotsLoading";
export default function EmployeesShiftWork(props) {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);  
  const [loadingReport, setLoadingReport] = useState(false)
  useEffect(() => {
    getAssignedEmployees();
  }, []);

  const getAssignedEmployees = () => {
    setLoading(true);
    EmployeeTeamsService.getAssignedEmployees().then((response) => {
      if (response.status === 200) {
        setEmployees(response.data.employees);
      }
    });
  };

  const downloadFile = () => {
    const fetcher = ReportsService.downloadWorkShiftScheduleReport
    setLoadingReport(true)
    fetcher({})
        .then(response => {
            if (response.status === 200) {
                try {
                    const fileName = `turnos-${Date.now()}.xlsx`
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url
                    link.download = fileName
                    link.click()
                    setLoadingReport(false)
                    return

                } catch (error) {
                    setLoadingReport(false)
                    console.log(error)
                }
            }
            else {
                setLoadingReport(false)
                console.log("Ocurrió un error al procesar la información.")
            }
        })
}
  
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-end">
      <button
        onClick={(e)=>downloadFile()}
        className="bg-indigo-700 hover:bg-indigo-800 text-white p-2 rounded-md flex items-center gap-2 min-w-[120px]" disabled={loadingReport ? true : false}>

          <><FaFileExcel /> Exportar jornadas</>
      </button>
      </div>
      {employees.map((employee, index) => {
        return (
          <EmployeeShiftWorkItem key={`employee_schedule_${index}`} employee={employee}/>
        );
      })}
    </div>
  );
}

import AuthService from "../services/auth.service";
import { useEffect, useState } from "react";
import verifyAccess from "./verifyAccess";
export default function RequirePermission(props) {
  const { requirePermission = true, allowedRoles = [] } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(AuthService.isLoggedIn());
  }, []);
  return (
    <>
      {requirePermission ? (
        isLoggedIn && verifyAccess({ allowedRoles }) ? (
          <>{props.children}</>
        ) : (
          <></>
        )
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}

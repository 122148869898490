import { useState, useEffect } from "react"
import Container from "../../Container"
import { useParams, useLocation } from "react-router-dom"
import { MdPeople, MdEdit, MdPersonAdd, MdEmail, MdBusiness } from "react-icons/md"
import TabContent from "../TabContent"
import TabAction from "../TabAction"
import Documents from "./Documents/DocumentsTab"
import EmployeesService from "../../../../services/employees.service"
import PersonalInformationTab from "./PersonalInformation/PersonalInformationTab"
import ContractsTab from "./Contracts/ContractsTab"
import RetributionTab from "./Retribution/RetributionTab"
import ProfileTab from "./Profile/ProfileTab"
import DocumentsTab from "./Documents/DocumentsTab"
import useQuery from "../../../../custom_hooks/useQuery"
export default function EmployeeDetail(props) {
    const { uuid } = useParams()
    const query = useQuery()
    const [tabSelected, setTabSelected] = useState('profile')
    const [enableTab, setEnableTab] = useState(false)
    const [employee, setEmployee] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (uuid) {
            //Obtenemos detallado de empleado
            getEmployee()
        }
    }, [])

    const getEmployee = () => {
        EmployeesService.getEmployee(uuid)
            .then(response => {
                if (response.status === 200) {
                    setEmployee(response.data.data.employee)
                    setLoading(false)
                }
            })
    }

    const handleTabSelected = (option) => {
        setTabSelected(option)
    }

    return (
        <Container navLocation={"empleados"}>
            <div className="bg-white drop-shadow-md">
                <div className="bg-indigo-700 text-white rounded-t-md drop-shadow-md">
                    <div className="flex items-center gap-4 pt-4 pb-2 px-8 border-b border-indigo-500">
                        <div>
                            <span><MdPeople className="w-8 h-8" /></span>
                        </div>
                        <div>
                            {
                                !loading ?
                                    <>
                                        <p className="text-xl font-bold">
                                            {employee.name} {employee.lastName} {employee.secondSurname}
                                        </p>
                                        <p className="text-sm flex items-center gap-2">
                                            <MdEmail /> {employee.email}
                                        </p>
                                        {
                                            employee?.businessEmail &&
                                            <p className="text-sm flex items-center gap-2">
                                                <MdBusiness /> {employee.businessEmail}
                                            </p>
                                        }
                                    </>
                                    :
                                    <div className="animate-pulse flex flex-col gap-2">
                                        <div className="h-3 w-[100px]  bg-slate-200 rounded col-span-1"></div>
                                        <div className="h-3 w-[180px]  bg-slate-200 rounded col-span-1"></div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="px-8 py-2 flex justify-between">
                        <div className="flex gap-4">
                            <TabAction handleTabSelected={handleTabSelected} optionKey={'profile'} tabSelected={tabSelected} label={'Perfil'} />
                            <TabAction handleTabSelected={handleTabSelected} optionKey={'personal'} tabSelected={tabSelected} label={'Personal'} />
                            <TabAction handleTabSelected={handleTabSelected} optionKey={'contracts'} tabSelected={tabSelected} label={'Contratos'} />
                            <TabAction handleTabSelected={handleTabSelected} optionKey={'retribution'} tabSelected={tabSelected} label={'Retibución'} />
                            <TabAction handleTabSelected={handleTabSelected} optionKey={'docs'} tabSelected={tabSelected} label={'Documentos'} />
                        </div>
                    </div>
                </div>
                <div className="px-8 py-16">
                    <div>
                        <TabContent
                            optionKey={'profile'}
                            tabSelected={tabSelected}
                        >
                            <ProfileTab
                                optionKey={'profile'}
                                tabSelected={tabSelected}
                                employee={uuid}
                            />
                        </TabContent>
                        <TabContent
                            optionKey={'personal'}
                            tabSelected={tabSelected}
                        >
                            <PersonalInformationTab
                                optionKey={'personal'}
                                tabSelected={tabSelected}
                                employee={uuid}
                            />
                        </TabContent>
                        <TabContent
                            optionKey={'contracts'}
                            tabSelected={tabSelected}
                        >
                            <ContractsTab
                                optionKey={'contracts'}
                                tabSelected={tabSelected}
                                employee={uuid}
                            />
                        </TabContent>
                        <TabContent
                            optionKey={'retribution'}
                            tabSelected={tabSelected}
                        >
                            <RetributionTab
                                optionKey={'retribution'}
                                tabSelected={tabSelected}
                                employee={uuid}
                            />
                        </TabContent>
                        <TabContent
                            optionKey={'docs'}
                            tabSelected={tabSelected}
                        >
                            <DocumentsTab
                                optionKey={'docs'}
                                tabSelected={tabSelected}
                                employee={uuid}
                            />
                        </TabContent>
                    </div>
                </div>

            </div>
        </Container>
    )
}
import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getLegalEntities = () => {
  return axios.get(`${API_URL}/legal-entities`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createLegalEntities = (data) => {
  return axios.post(`${API_URL}/legal-entities`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
    getLegalEntities,
    createLegalEntities
}
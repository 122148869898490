import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import { toast } from "react-hot-toast"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import dateFormat from "../../../../../custom_hooks/dateFormat"
import { MdNavigateNext } from "react-icons/md"
export default function BankInformation(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [employeeBankInformation, setEmployeeBankInformation] = useState(null)
    const [bankInstitutionsSelector, setBankInstitutionsSelector] = useState([])
    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeeBankInformation()
        }
    }, [employee, active])

    const getEmployeeBankInformation = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeeBankInformation(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }

    const formatData = (data) => {
        const { bankInformation, catalogs } = data
        setEmployeeBankInformation(bankInformation)
        setFormData(bankInformation)
        formatCatalogs(catalogs)
    }

    const formatCatalogs = (catalogs) => {
        const { bankInstitutions } = catalogs
        setBankInstitutionsSelector(bankInstitutions.map((item) => ({ value: item.id, label: `${item.code} - ${item.description}` })))
    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            setFormData(employeeBankInformation)
        }
        else {
            setIsNew(true)
            setFormData()
        }
        setLoading(false)
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = async (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "bankInstitution": data?.bankInstitutionId ?? "",
            "ePaymentBranch": data?.ePaymentBranch ?? "",
            "accountNumber": data?.accountNumber ?? "",
            "interbankClabe": data?.interbankClabe ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeBankInformation(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployeeBankInformation()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }
    return (
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Información bancaria
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} showNew={false} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="bankInstitution"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="bankInstitution"
                                                                active={activateForm}
                                                                type="select"
                                                                options={bankInstitutionsSelector}
                                                                label="Institución bancaria"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="ePaymentBranch"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="ePaymentBranch"
                                                                active={activateForm}
                                                                label="Sucursal pago electrónico"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="accountNumber"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="accountNumber"
                                                                active={activateForm}
                                                                label="Número de cuenta"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="interbankClabe"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="interbankClabe"
                                                                active={activateForm}
                                                                label="CLABE interbancaria"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
import CustomInput from "../../CustomInput"
import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import EmployeesService from "../../../../../services/employees.service"
import FormOptions from "../../../FormOptions"
import LoadingCard from "../../../../Utils/LoadingCard"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import { toast } from "react-hot-toast"
import dateFormat from "../../../../../custom_hooks/dateFormat"
import EmployeeStatusNotice from "./EmployeeStatusNotice"
export default function Positions(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [employeePosition, setEmployeePosition] = useState()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [departments, setDepartments] = useState([])
    const [departmentsSelector, setDepartmentsSelector] = useState([])
    const [positions, setPositions] = useState([])
    const [positionsSelector, setPositionsSelector] = useState([])
    const [isNew, setIsNew] = useState(false)
    const [employeeStatusNotice, setEmployeeStatusNotice] = useState(null)
    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeePosition()
        }
    }, [employee, active])

    useEffect(() => {
        if (departments.length > 0 && employeePosition) getPositions()
    }, [departments, employeePosition])

    const getEmployeePosition = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeePosition(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                }
            })
    }

    const getPositions = (department = false, useDefaultPosition = false) => {
        const target = department ? department : employeePosition?.position?.department?.id
        let filter = []
        departments.map((item) => {
            if (item.id === parseInt(target)) {
                filter = item.positions
            }
        })
        const format = filter.map((item) => ({ value: item.id, label: item.name }))
        setPositions(filter)
        setPositionsSelector(format)
        useDefaultPosition && setValue("position", format[0].value)
    }

    const formatData = (data) => {
        const { position, catalogs, employeeStatusNotice } = data
        setEmployeeStatusNotice(employeeStatusNotice)
        formatCatalogs(catalogs)
        if (position === null) {
            setLoadingComponent(false)
            handleFormOptionAction({ action: "new", activateForm: false })
            return
        }        
        setEmployeePosition(position)
        setFormData(position)
        setLoadingComponent(false)
    }

    const formatCatalogs = (catalogs) => {
        const deptos = catalogs.departments
        const format = deptos.map((item) => ({ value: item.id, label: item.name }))
        setDepartments(deptos)
        setDepartmentsSelector(format)
    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            //Si anteriormente fue nuevo y tiene algo en employeePosition
            if (isNew && employeePosition) {
                getPositions(employeePosition?.position?.department?.id)
                setIsNew(false)
                setFormData(employeePosition)
            }
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const handleOnChangeDepto = (e) => {
        setValue("department", e.target.value)
        getPositions(e.target.value, true)
    }

    const setFormData = (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "position": data?.position?.id ?? "",
            "department": data?.position?.department?.id ?? "",
            "fromDate": dateFormat(data?.fromDate ?? new Date()),
            "toDate": dateFormat(data?.toDate ?? new Date())
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeePosition(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    setIsNew(false)
                    getEmployeePosition()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return (
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md overflow-hidden">
                        <EmployeeStatusNotice employeeStatusNotice={employeeStatusNotice} employee={employee} />
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Perfil del puesto
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="department"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="deptoSelector"
                                                            type="select"
                                                            options={departmentsSelector}
                                                            value={value}
                                                            active={activateForm}
                                                            label="Departamento"
                                                            onChange={handleOnChangeDepto}
                                                        />
                                                    )}
                                                />
                                                {errors.department && <FieldRequiredError />}
                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="position"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="positionSelector"
                                                            type="select"
                                                            options={positionsSelector}
                                                            active={activateForm}
                                                            label="Puesto"
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                                {errors.position && <FieldRequiredError />}
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="fromDate"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="fromDate"
                                                            active={activateForm}
                                                            label="Fecha inicio puesto"
                                                            type="date"
                                                            onChange={onChange}
                                                            defaultValue={value}
                                                            value={value}
                                                        />
                                                    )}
                                                />

                                            </div>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name="toDate"
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <CustomInput
                                                            eKey="toDate"
                                                            active={activateForm}
                                                            label="Fecha fin puesto"
                                                            type="date"
                                                            onChange={onChange}
                                                            defaultValue={value}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <FormDefaultActions
                                            activateForm={activateForm}
                                            handleCancel={handleCancel}
                                            loading={loading}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }

        </div>
    )
}
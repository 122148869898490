import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getDirectoryFiles = (directory) => {
    return axios.get(`${API_URL}/files/employees/${directory}/directory-files`)
        .then(response => {
            return response
        })
        .catch(error => {
            if (error.code === "ERR_NETWORK") {
                error.response = { status: 500 }
            }
            return error.response
        })
}

const uploadEmployeeFiles = (employee, formData) => {
    return axios.postForm(`${API_URL}/files/employees/${employee}/upload-file`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(response => {
            return response
        })
        .catch(error => {
            if (error.code === "ERR_NETWORK") {
                error.response = { status: 500 }
            }
            return error.response
        })
}

const downloadFile = (key) => {
    return axios.get(`${API_URL}/files/employees/download/${key}`, {
        responseType: 'arraybuffer'
    })
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

export default {
    getDirectoryFiles,
    uploadEmployeeFiles,
    downloadFile
}
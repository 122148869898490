import { useMemo } from "react";
import { useTable } from "react-table"
import TableLoader from "../TableLoader";
export default function Table(props) {
  const { tableColumns = [], tableItems = [], loading = false } = props
  const data = useMemo(
    () => tableItems
  )
  const columns = useMemo(
    () => tableColumns
  )
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance
  return (
    <>
      {
        loading ?
          <TableLoader />
          :
          <table
            {...getTableProps()}
            className="w-full text-sm text-left ">
            <thead
              className="text-gray-700 uppercase bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      className="py-3 px-6"
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className="bg-white border-b"
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="py-4 px-6 font-bold"
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
      }
    </>
  )
}
import { MdHelp, MdCloudDownload, MdRemoveRedEye, MdCalendarToday, MdPerson, MdSync } from "react-icons/md"
import { format } from "date-fns"
import FileIcon from "./FileIcon"
import FileDownloader from "./FileDownloader"
export default function FileItem(props) {
    const { handleMasterFilePreview } = props
    const { name, createdAt, files, createdBy } = props.masterFile
    const handleFilePreview = (event) => {
        event.stopPropagation()
        handleMasterFilePreview(props.masterFile)
    }

    const handleFileDownload = (event) => {
        event.stopPropagation()
        alert("File Download")
    }

    return (
        <div
            onClick={(e) => handleFilePreview(e)}
            className="h-fit w-[200px] bg-slate-100 drop-shadow-md border rounded-md p-4 flex flex-col gap-4 mb-4 hover:bg-slate-100 hover:cursor-pointer transition-all duration-300"
        >
            <div className="flex w-full gap-2">

                <p className="text-xs font-bold truncate text-ellipsis">{files[0]?.fileName ?? files[0]?.name ?? ""}</p>

            </div>
            <div className="flex justify-center">
                <FileIcon size="lg" fileType={files[0].type} />
            </div>

        </div>
    )
}
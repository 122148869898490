import { MdUploadFile, MdSecurity } from "react-icons/md"
import MasterFileItem from "./MasterFileItem"
import FilePreview from "./FilePreview"
import { useEffect, useState } from "react"
import NewFileForm from "./NewFileForm"
import NoFiles from "./NoFiles"
import FilesService from "../../../services/files.service"
export default function DirectoryMasterFiles(props) {
    const { directory, masterFiles = [], handleBack } = props
    const [masterFileItems, setMasterFileItems] = useState([])
    const [masterFileSelected, setMasterFileSelected] = useState([])
    const [showFilePreview, setShowFilePreview] = useState(false)
    const [showNewFileForm, setShowNewFileForm] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const [showItemPermissionsForm, setShowItemPermissionsForm] = useState(false)
    const handleMasterFilePreview = (masterFile) => {
        setShowFilePreview(true)
        setMasterFileSelected(masterFile)
    }

    useEffect(() => {
        getDirectoryFiles()
    }, [])

    const getDirectoryFiles = () => {
        try {
            setLoadingData(true)
            FilesService.getDirectoryFiles(directory).then(response => {
                console.log(response)
                if (response.status === 200) {
                    setMasterFileItems(response.data.data.directory?.masterFiles ?? [])
                    setLoadingData(false)
                }
            })
        } catch (error) {
            console.log(error)
            setLoadingData(false)
            return []

        }
    }

    const handleMasterFileGenerated = async (response) => {
        setMasterFileItems([...response, ...masterFileItems])
    }

    const handleMasterFileUpdated = (res) => {
        const file = res[0]
        //Get the masterFile uuid
        const masterFileItemsUpdate = masterFileItems.map((mf) => {
            if (mf.uuid === file.uuid) {
                mf.files = file.files
            }
            return mf
        })

        setMasterFileItems(masterFileItemsUpdate)
    }

    return (
        <div>
            <div className="pb-4 border-b flex gap-2 items-end justify-between">
                <div className="flex flex-1 flex-col gap-4">
                    <div>
                        <p className="font-bold text-lg">{directory.name}</p>
                        <span className="text-sm">{directory.description}</span>
                    </div>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => setShowNewFileForm(true)}
                        type="button"
                        className="h-fit font-medium flex items-center gap-2 bg-indigo-600 drop-shadow-md hover:bg-indigo-800 text-white rounded-md py-2 px-4">
                        <MdUploadFile /> Nuevo archivo
                    </button>
                </div>
            </div>
            <div className="py-8 flex flex-row gap-4">
                <div className="w-full transition-all duration-300">
                    <div className="w-full flex flex-row flex-wrap h-fit gap-4">
                        {
                            !loadingData ?
                                <>
                                    {
                                        masterFileItems.length > 0 ?
                                            masterFileItems.map((mfile, mfilekey) => {
                                                if (mfile?.files.length > 0 ?? false) {
                                                    return (
                                                        <MasterFileItem
                                                            key={`mFileItem_${mfilekey}`}
                                                            masterFile={mfile}
                                                            handleMasterFilePreview={handleMasterFilePreview}
                                                        />
                                                    )
                                                }
                                            })
                                            :
                                            <NoFiles />
                                    }
                                </>
                                :
                                <>
                                    <div className="w-full">
                                        <div className="animate-pulse flex space-x-4">
                                            <div className="flex w-full flex-col py-1 gap-4">
                                                <div className="w-full h-12 bg-slate-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                <div
                    className={`${showFilePreview ? 'hidden lg:block' : 'hidden'} w-4/12 max-w-[300px]`}>
                    <FilePreview
                        directory={directory}
                        masterFile={masterFileSelected}
                        handleClose={setShowFilePreview}
                        responseHandler={handleMasterFileUpdated}
                    />
                </div>
            </div>
            <NewFileForm
                allowMultiple={true}
                type="masterfile"
                formKey="newFileForm"
                modalName={"Archivos del empleado"}
                showModal={showNewFileForm}
                setShowModal={setShowNewFileForm}
                targetDirectory={directory}
                responseHandler={handleMasterFileGenerated}
            />
        </div>
    )
}
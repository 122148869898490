import Address from "./Address"
import BirthPlace from "./BirthPlace"
import Details from "./Details"
import PersonalInformation from "./PersonalInformation"
import { useState, useEffect } from "react"
export default function PersonalInformationTab(props){
    const { optionKey, enableTab, tabSelected } = props
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(tabSelected === optionKey)
    }, [enableTab, tabSelected])
    const objProps = {...props, ...{ active }}
    return(
        <div className="flex flex-col gap-8">
            <PersonalInformation {...objProps} />
            <Details {...objProps} />
            <BirthPlace {...objProps} />
            <Address {...objProps} />
        </div>
    )
}
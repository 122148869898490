import { useMemo, useReducer, useState, useEffect } from "react"
import TableLoader from "./TableLoader"
import { MdNavigateBefore, MdNavigateNext, MdFirstPage, MdLastPage, MdArrowDropDown, MdArrowDropUp, MdOutlineSearchOff, MdFilterAlt, MdSearch } from "react-icons/md"
import {
    flexRender,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef
} from "@tanstack/react-table";
export default function ReactTable(props) {
    const { tableColumns = [], tableItems = [], loading = false, filterKeys = [] } = props
    const rerender = useReducer(() => ({}), {})[1]
    const [filterTarget, setFilterTarget] = useState(filterKeys[0]?.key)
    const [filterInput, setFilterInput] = useState('')
    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    })
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        setFilterData(tableItems)
    }, [tableItems]);

    const data = useMemo(
        () => filterData
    )
    const columns = useMemo(
        () => tableColumns
    )

    const fetchDataOptions = {
        pageIndex,
        pageSize,
    }
    const defaultData = useMemo(() => [], [])

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const handleFilter = () => {
        //filtramos y seteamos
        let prev = [...tableItems]
        let filter = prev
        const filterTarget = filterInput?.trim()?.toLocaleLowerCase() ?? false
        if (filterTarget) {
            filter = [...filterData]
            //Genereamos un nuevo array con las keys proporcionadas            
            filter = prev.filter((item) => {
                //Validamos en todas las filterKeys proporcionadas
                const search_arr = filterKeys.map((key) => item[key]?.toLowerCase())
                const evaluate = search_arr.filter((x) => x.includes(filterTarget)).length > 0
                return evaluate
            })
        }
        setFilterData(filter)
    }

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        state: { pagination },
        onPaginationChange: setPagination,
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });
    return (
        <>
            {
                loading ?
                    <TableLoader />
                    :
                    <div className="--border --rounded-md --drop-shadow-md --overflow-hidden">
                        <div className="p-8 flex gap-4 border-x border-t rounded-t-md">
                            <input
                                placeholder="Buscar empleado..."
                                value={filterInput}
                                onChange={(e) => (setFilterInput(e.target.value))}
                                className="w-full border border-slate-300 rounded-md focus:outline-none focus:border-indigo-700 focus:ring-1 focus:ring-indigo-700"
                                type="text" />
                            <button
                                onClick={() => handleFilter()}
                                type="button"
                                className="flex justify-center items-center gap-2 w-fit px-8 bg-indigo-700 hover:bg-indigo-500 text-white rounded-md font-bold">
                                Buscar <MdSearch />
                            </button>
                        </div>
                        <div className="border-x border-b rounded-b-md">
                            <table className="w-full text-sm text-left ">
                                <thead className="text-gray-700 uppercase bg-slate-100 text-xs rounded-md overflow-hidden">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id} className="rounded-md">
                                            {headerGroup.headers.map(header => (
                                                <th
                                                    key={header.id}
                                                    className="py-3 px-6"
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id} className="bg-white border-b border-slate-100 hover:bg-gray-50">
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id} className="py-4 px-6 font-bold">
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pagination flex flex-row items-center gap-4 justify-between px-8 py-4 text-sm">
                                <select
                                className="rounded-md border border-indigo-800 text-sm"
                                    value={table.getState().pagination.pageSize}
                                    onChange={e => {
                                        table.setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Mostrar {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <div>
                                    <span>
                                        Página{' '}
                                        <strong>
                                            {table.getState().pagination.pageIndex + 1} de{' '}
                                            {table.getPageCount()}
                                        </strong>
                                    </span>
                                    {' | '}
                                    <span>
                                        Ir a página:{' '}
                                        <input
                                            className="w-[60px] text-sm rounded-md border border-indigo-800"
                                            type="number"
                                            defaultValue={table.getState().pagination.pageIndex + 1}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                table.setPageIndex(page)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.setPageIndex(0)}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <MdFirstPage className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <MdNavigateBefore className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <MdNavigateNext className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <MdLastPage className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
import { useState, useContext, useEffect } from "react"
import { MdBusiness, MdExpandMore, MdArrowForward } from "react-icons/md"
import AppContext from "../../../context/AppContext"
import Spinner from "../../Spinner"
export default function LegalEntitiesSelector() {
    const [show, setShow] = useState(false)
    const { legalEntities, legalEntitySelected, handleLegalEntitySelected } = useContext(AppContext)
    const [optionSelected, setOptionSelected] = useState({ key: 0, label: "Entidades legales"})
    const handleOptionSelected = (option) =>{
        handleLegalEntitySelected(option)
        setShow(false)
    }
    return (
        <div className="relative">
            <button
                disabled={legalEntities.loading}
                onClick={() => setShow(!show)}
                type="button"
                className={`w-[250px] bg-white border ${show ? 'border-indigo-800' : 'border-slate-200'}  hover:bg-slate-100 rounded-md drop-shadow-md p-4 flex items-center justify-between gap-2 text-sm font-medium`}>
                <span className="flex gap-2 truncate">
                    <MdBusiness className="w-5 h-5" />
                    <span className="truncate font-bold">{legalEntitySelected.label}</span>
                </span>
                <span className="flex items-center">
                    {
                        legalEntities.loading ?
                            <Spinner />
                            :
                            <MdExpandMore className={`w-5 h-5 transition-all ease-out duration-300 ${show ? 'rotate-180' : ''}`} />
                    }
                </span>
            </button>
            <div className={`transition-all ${show ? 'opacity-1 scale-100' : 'opacity-0 scale-90'} mt-2 min-w-full w-max absolute bg-white border border-slate-200 drop-shadow-md rounded-md p-4`}>
                <ul className="text-sm flex flex-col gap-2">
                    {
                        legalEntities.data.map((item, index) => {
                            return (
                                <li key={`legalEntity_${index}`}>
                                    <button
                                        onClick={ () => handleOptionSelected(item)}
                                        className={`group ${legalEntitySelected.key === item.uuid ? 'bg-slate-100 text-indigo-700' : '' } p-2 flex items-center gap-2 rounded-md hover:bg-slate-100 hover:text-indigo-700 w-full text-start `}
                                    >
                                        {item.name}
                                        <MdArrowForward className="group-hover:opacity-100 opacity-0" />
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
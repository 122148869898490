import Positions from "./Positions"
import Locations from "./Locations"
import { useState, useEffect } from "react"
export default function ProfileTab(props){
    const { optionKey, enableTab, tabSelected } = props
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(tabSelected === optionKey)
    }, [enableTab, tabSelected])
    const objProps = {...props, ...{ active }}
    return(
        <div className="flex flex-col gap-8">
            <Positions {...objProps} />
            <Locations {...objProps} />
        </div>
    )
}
import { useState } from "react";
import ModalContext from "../context/ModalContext";

export default function ModalProvider(props) {
    const { setShowModal } = props
    const handleShowModal = (action) => {
        setShowModal(action)
    }
    return (
        <ModalContext.Provider value={{ handleShowModal }}>
            {props.children}
        </ModalContext.Provider>
    )
}
import { useState } from "react"
import { useForm } from "react-hook-form"
import AuthService from "../services/auth.service"
import { useNavigate } from "react-router-dom"
import DotsLoading from "./DotsLoading"
export default function Login(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ show: false, message: 'Error al procesar la solicitud' })
    const { register, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = data => {
        setLoading(true)
        setErrorMessage({ show: true, message: '' })
        AuthService.login(data.email, data.password)
            .then(response => {
                if (response.status === 200) {
                    navigate("/")
                }
                else if (response.status === 400) {
                    setErrorMessage({ show: true, message: 'Credenciales invalidas' })
                }
                else {
                    setErrorMessage({ show: true, message: 'Error al procesar la solicitud' })
                }
                setLoading(false)
            })
            .catch(error => {
                setErrorMessage({ show: true, message: 'Error al procesar la solicitud' })
                setLoading(false)
            })
    }
    return (
        <div className="flex inset-0 h-[100vh] justify-center items-center container">
            <div className="w-full md:w-2/6 max-w-[480px] bg-white p-8 border border-gray-200 rounded-md shadow-md">
                <div className="pb-8 flex justify-center flex-col items-center gap-4">
                    <img src="/files/logo-hotelesmia.png" alt="" width={100} height={89} className="" />
                    <h1 className="text-center font-bold text-2xl">MÍA RRHH</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative flex gap-4 pb-4">
                        <div className="w-full">
                            <label htmlFor="" className="block mb-2 text-sm">Correo</label>
                            <input
                                type="email"
                                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                                {...register("email", { required: "Campo requerido" })}
                            />
                            <p className="text-red-800 text-xs pt-1">{errors.email?.message}</p>
                        </div>
                    </div>
                    <div className="relative flex gap-4 pb-4">
                        <div className="w-full">
                            <label htmlFor="" className="block mb-2 text-sm">Contraseña</label>
                            <input
                                type="password"
                                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                                {...register("password", { required: "Campo requerido" })}
                            />
                            <p className="text-red-800 text-xs pt-1">{errors.password?.message}</p>
                        </div>
                    </div>
                    <p className="text-red-800 text-center pt-2">{errorMessage.show ? errorMessage.message : ''}</p>
                    <div className="pt-4">
                        <button className={`w-full text-white bg-indigo-700 hover:bg-indigo-500 p-2 rounded-md font-bold flex justify-center`} disabled={loading ? true : false}>
                            {
                                loading ?
                                    <DotsLoading />
                                    :
                                    <span>Entrar</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default function TableLoader() {
    return (
        <div className="drop-shadow-lg bg-white rounded-lg p-4 w-full">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-10 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-4 gap-4">
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-5 bg-slate-200 rounded col-span-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
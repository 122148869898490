import { useState, useEffect, useContext } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import DotsLoading from "../../DotsLoading";
import ModalContext from "../../../context/ModalContext";
import ScheduleDayItem from "./ScheduleDayItem";
import CatalogsService from "../../../services/catalogs.service";
import ShiftWorkScheduleService from "../../../services/shiftworkschedule.service";
import { toast } from "react-hot-toast";
import dateFormat from "../../../custom_hooks/dateFormat";
import { MdDelete } from "react-icons/md";
export default function ShiftWorkScheduleForm(props) {
  const {
    isNew,
    employeeSelected = null,
    setReloadData,
    shiftWorkSelected = null,
  } = props;
  const [loading, setLoading] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const { handleShowModal } = useContext(ModalContext);
  const [scheduleDays, setScheduleDays] = useState([]);
  const [scheduleDaysSelector, setScheduleDaysSelector] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
  } = useForm();
  const {
    fields,
    append,
    update,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "days", // unique name for your Field Array
  });

  useEffect(() => {
    getWeekDays();
    if (!isNew) {
      //set Values
      setFormValues();
    }
  }, []);

  useEffect(() => {
    if (scheduleDays.length > 0) {
      //Verificamos si tiene dias configurados
      handleWeekDays(weekDays, scheduleDays);
    }
  }, [weekDays, scheduleDays]);

  const setFormValues = () => {
    setValue("name", shiftWorkSelected?.name ?? "");
    setValue("fromDate", dateFormat(shiftWorkSelected?.fromDate ?? ""));
    setValue("toDate", dateFormat(shiftWorkSelected?.toDate ?? ""));
    setScheduleDays(shiftWorkSelected?.weekDays ?? []);
  };

  const getWeekDays = () => {
    CatalogsService.getWeekDays().then((response) => {
      if (response.status === 200) {
        setFormValues();
        setWeekDays(response.data.data.weekDays);
        handleWeekDays(response.data.data.weekDays);
      }
    });
  };

  const handleWeekDays = (days, daysConfigured = []) => {
    let obj = days.map((day, index) => {
      const scheduleDay = getDayConfigured(day.id, daysConfigured);
      let startTime = scheduleDay?.shiftWorkScheduleDays?.startTime.replace(
        /:[^:]*$/,
        ""
      );
      let endTime = scheduleDay?.shiftWorkScheduleDays?.endTime.replace(
        /:[^:]*$/,
        ""
      );
      return {
        id: day.id,
        code: day.code,
        name: day.name,
        startTime: startTime,
        endTime: endTime,
        enableRemove: (startTime && endTime) ? true : false
      };
    });
    replace(obj);
    setScheduleDaysSelector(obj);
  };

  const submit = (data) => {
    setLoading(true);
    const params = {
      ...data,
      ...{
        employees: [employeeSelected?.id ?? 0],
        shiftWorkScheduleID: shiftWorkSelected?.id ?? 0,
      },
    };
   //console.log(params);
    //return;
    ShiftWorkScheduleService.createEmployeeShiftWork(params).then(
      (response) => {
        const { error = "Ocurrió un error al actualizar la información" } =
          response.data;
        if (response.status === 201) {
          handleShowModal(false);
          toast.success("Se actualizó la información correctamente");
          setReloadData(true);
        } else {
          toast.error(error);
        }
        setLoading(false);
      }
    );
  };

  const getScheduleDayByID = async (ID) => {
    return scheduleDays.filter((day) => day.id === ID)[0];
  };

  const getDayConfigured = (target, days) => {
    console.log(
      "getDayConfigured: ",
      days.filter((day) => day.id === target)
    );
    return days.filter((day) => day.id === target)[0];
  };

  const handleOnChangeHourInput = (event, target, index) => {
    let obj =  [...scheduleDaysSelector]
    obj[index][target] = event.target.value
    obj[index].enableRemove = true
    setScheduleDaysSelector(obj)
  };

  const handleRemoveScheduleHour = (index) => {
    let obj =  [...scheduleDaysSelector]
    obj[index].startTime = undefined
    obj[index].endTime = undefined
    obj[index].enableRemove = false
    setValue(`days.${index}.startTime`, undefined)
    setValue(`days.${index}.endTime`, undefined)
    setScheduleDaysSelector(obj)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="relative flex flex-col gap-4 pb-4">
          <div className="w-full">
            <label htmlFor="" className="block mb-2 text-sm font-medium">
              Nombre del horario
            </label>
            <input
              type="text"
              className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
              {...register("name", { required: "Campo requerido" })}
            />
            <p className="text-red-800 text-xs pt-1">{errors.name?.message}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
          <div className="w-full">
            <label htmlFor="" className="block mb-2 text-sm font-medium">
              Fecha inicio
            </label>
            <input
              type="date"
              className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
              {...register("fromDate", { required: "Campo requerido" })}
            />
            <p className="text-red-800 text-xs pt-1">
              {errors.fromDate?.message}
            </p>
          </div>
          <div className="w-full">
            <label htmlFor="" className="block mb-2 text-sm font-medium">
              Fecha fin
            </label>
            <input
              type="date"
              className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
              {...register("toDate", { required: "Campo requerido" })}
            />
            <p className="text-red-800 text-xs pt-1">
              {errors.toDate?.message}
            </p>
          </div>
        </div>
        <div className="border-t pt-4">
          <div className="pb-4">
            <span className="text-sm font-bold">Horarios laborales</span>
          </div>
          <div className="flex flex-col gap-2">            
            {scheduleDaysSelector.map((day, indexDay) => {
              return (
                <ScheduleDayItem
                  key={`schedule-day-item-${indexDay}`}
                  day={day}
                >
                  <div className="w-auto">
                    <label
                      htmlFor=""
                      className="block mb-1 text-xs font-medium"
                    >
                      Hora inicio
                    </label>
                    <input
                      type="time"
                      defaultValue={day.startTime ?? ""}
                      className="text-xs font-bold placeholder:text-slate-400 block bg-white w-fit h-[30px] border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                      {...register(`days.${indexDay}.startTime`)}
                      onChange={(e) => handleOnChangeHourInput(e, 'startTime', indexDay)}
                    />
                  </div>
                  <div className="w-auto">
                    <label
                      htmlFor=""
                      className="block mb-1 text-xs font-medium"
                    >
                      Hora fin
                    </label>
                    <input
                      type="time"
                      defaultValue={day.endTime ?? ""}
                      className="text-xs font-bold placeholder:text-slate-400 block bg-white w-fit h-[30px] border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                      {...register(`days.${indexDay}.endTime`)}
                      onChange={(e) => handleOnChangeHourInput(e, 'endTime', indexDay)}
                    />
                  </div>
                  {day.enableRemove && (
                    <button onClick={() => handleRemoveScheduleHour(indexDay)} className="flex items-center hover:text-red-700">
                      <MdDelete />
                    </button>
                  )}
                </ScheduleDayItem>
              );
            })}
          </div>
        </div>
        <div className="pt-8 flex gap-4 md:gap-8">
          <button
            onClick={() => handleShowModal(false)}
            type="button"
            className="transition-all w-full p-2 rounded-md bg-slate-200 font-bold hover:bg-slate-100"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`transition-all w-full text-white bg-indigo-700 hover:bg-indigo-500 p-2 rounded-md font-bold flex justify-center`}
            disabled={loading ? true : false}
          >
            {loading ? <DotsLoading /> : <span>Guardar</span>}
          </button>
        </div>
      </form>
    </div>
  );
}

import Container from "../Container"
import { FaBusinessTime } from "react-icons/fa"
import EmployeesShiftWork from "./EmployeesShiftWork"
export default function ShiftWorks(){
    return(
        <>
            <Container navLocation={"turnos"}>
            <div className="bg-white">
                    <div className="bg-indigo-700 text-white rounded-t-md drop-shadow-md">
                        <div className="flex items-center gap-4 pt-4 pb-2 px-8 border-b border-indigo-500">
                            <div>
                                <span><FaBusinessTime className="w-8 h-8" /></span>
                            </div>
                            <div>
                                <p className="text-xl font-bold">Turnos</p>
                                <p className="text-sm">Gestión de turnos por empleado</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-8">
                        <EmployeesShiftWork />
                    </div>

                </div>
            </Container>
        </>
    )
}
import { MdBlurOn } from "react-icons/md"
export default function NoFiles(props) {
    const { label = "No se encontraron archivos en este directorio." } = props
    return (
        <div className="py-8 w-full flex flex-col gap-4 justify-center items-center">
            <MdBlurOn className="w-[8rem] h-[8rem] text-slate-200" />
            <div>
                <p className="text-slate-400 text-sm">{label}</p>
            </div>
        </div>
    )
}
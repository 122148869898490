import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getEmployeeSchedulesAssigned = (employee) => {
  return axios.get(`${API_URL}/shift-work-schedule/employees/${employee}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createEmployeeShiftWork = (data) =>{
  return axios.post(`${API_URL}/shift-work-schedules/employees`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getEmployeeSchedulesAssigned,
  createEmployeeShiftWork
}
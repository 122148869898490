import FilesService from "../../../services/files.service"
import { useState } from "react"
import DotsLoading from "../../DotsLoading"
export default function FileDownloader(props) {
    const { fileKey = "", uuid = 0 } = props
    const [loading, setLoading] = useState(false)
    const handleDownLoadCV = () => {
        setLoading(!loading)
        FilesService.downloadFile(uuid)
            .then(response => {
                if (response.status === 200) {
                    try {
                        const blob = new Blob([response.data], {type: "octet/stream"})
                        const link = document.createElement('a')
                        const file =  window.URL.createObjectURL(blob)
                        link.href = file
                        link.download = fileKey
                        link.click()
                        setLoading(false)
                    } catch (error) {
                        setLoading(false)
                    }
                }
                else {
                    setLoading(false)
                }
            })
    }
    return (
        <div className="w-full flex " onClick={() => handleDownLoadCV()}>
            {
                !loading ? 
                    <>{props.children}</>
                    :
                    <div className="flex w-full items-center justify-center -bg-black -opacity-20 -rounded-md p-2">
                        <DotsLoading />
                    </div>
            }
        </div>
    )
}
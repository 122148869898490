import Navigation from "./Navigation/Navigation"
import Header from "./Navigation/Header"
import AppContext from "../../context/AppContext"
import { useContext, useEffect } from "react"
export default function Container(props) {
    const { navLocation } = props
    const { setNavLocation } = useContext(AppContext)
    useEffect(()=>{
        setNavLocation(navLocation)
    }, [])
    return (
        <div className="flex flex-row">
            <Navigation />
            <div className="flex-1 basis-[0%]">
                <Header />
                <main>
                    <div className="m-auto max-w-[1200px]">
                        {props.children}
                    </div>
                </main>
            </div>
        </div>
    )
}
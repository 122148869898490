import Modal from "../../Modal/Modal"
import NewEmployeeForm from "./NewEmployeeForm"
export default function NewEmployee(props) {
    const { showModal, setShowModal, setReloadData } = props
    return (
        <Modal
            title={"Nuevo empleado"}
            description="Captura la información básica de tu nuevo empleado"
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <NewEmployeeForm setReloadData={setReloadData} />
        </Modal>
    )
}
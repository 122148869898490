import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Container from "../Container"
import JobForm from "./JobForm"
import JobsService from "../../../services/jobs.service"
import PropertiesService from "../../../services/properties.service"
import AreasService from "../../../services/areas.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function JobDetail(props) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isNew, setIsNew] = useState(false)
    const [job, setJob] = useState({
        id: undefined,
        name: '',
        description: '',
        place: '',
        status: true
    })
    const [jobProperty, setJobProperty] = useState({})
    const [jobArea, setJobArea] = useState({})
    const [properties, setProperties] = useState([])
    const [areas, setAreas] = useState([])
    const [loading, setLoading] = useState(false)
    const onSubmit = (props) => {
        setLoading(true)
        if (isNew) {
            props.id = props.id !== "" ? props.id : undefined
            JobsService.createJob(props)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                        setJob(response.data.data)
                        navigate("/jobs")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
        else {
            JobsService.updateJob(props)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            if (id === undefined) {
                setIsNew(true)
                AreasService.getAreas()
                    .then(response => {
                        if (response.status === 200) {
                            const data = response.data.data
                            setAreas(data.areas)
                        }
                    })
                PropertiesService.getProperties()
                    .then(response => {
                        if (response.status === 200) {
                            const data = response.data.data
                            setProperties(data.properties)
                        }
                    })
            }
            else {
                JobsService.getJob(id)
                    .then(response => {
                        if (response.status === 200) {
                            const data = response.data.data
                            setJob(data.job)
                            setJobProperty(data.jobProperty)
                            setJobArea(data.jobArea)
                            setProperties(data.properties)
                            setAreas(data.areas)
                        }
                    })
            }
        }
        return () => (isSubscribed = false)
    }, [])

    return (
        <>
            <Container>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Vacante</h1>
                </div>
                <div className="pt-2">
                    {
                        isNew ?
                            <p>Crear nueva vacante</p>
                            :
                            <p>Detalle vacante</p>
                    }
                </div>
                <div className="pt-8 w-full">
                    <div className="bg-white shadow-lg h-auto w-full p-8 rounded-lg">
                        <JobForm                    
                            id={id}
                            job={job}
                            jobProperty={jobProperty}
                            jobArea={jobArea}
                            submit={onSubmit}
                            loading={loading}
                            properties={properties}
                            areas={areas}
                            isNew={isNew}
                        />
                    </div>
                </div>
            </Container>
        </>

    )
}
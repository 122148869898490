import Container from "../Container"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AreasService from "../../../services/areas.service"
import Table from "../Table"
export default function Areas() {
    const navigate = useNavigate()
    const [areas, setAreas] = useState([])
    const [loading, setLoading] = useState(true)
    const tableColumns = [
        {
            Header: 'Código',
            accessor: 'code', // accessor is the "key" in the data
        },
        {
            Header: 'Descripción',
            accessor: 'description',
        },
        {
            Header: 'Status',
            accessor: 'statusBadge',
        },
        {
            Header: '',
            accessor: 'details',
        }
    ]
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            AreasService.getAreas()
                .then(response => {
                    if (response.status === 200) {
                        formatData(response.data.data.areas)
                    }
                })
        }
        return () => (isSubscribed = false)
    }, [])

    const formatData = (data) => {
        data.map(function (item) {
            item.statusBadge = <Badge status={item.status} />
            item.details = <a className="text-blue-600 font-bold hover:underline" href={`/areas/${item.id}`}>Detalles</a>
            return item
        })
        setAreas(data)
        setLoading(false)
    }
    function Badge({ status }) {
        let label = status ? 'Activo' : 'Inactivo'
        return (
            <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full ${status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}`}>
                {label}
            </span>
        )
    }
    return (
        <>
            <Container>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Vacantes</h1>
                    <button
                        className="py-2 px-4 bg-blue-600 text-white rounded-lg font-bold"
                        onClick={() => navigate("/areas/create")}
                    >
                        Nueva Área
                    </button>
                </div>
                <div className="pt-8">
                    <Table tableColumns={tableColumns} tableItems={areas} loading={loading} />
                </div>
            </Container>
        </>
    )
}
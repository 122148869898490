
import { FaTimes } from "react-icons/fa"
import { createPortal } from "react-dom"
import { useEffect, useState } from "react"
import ModalProvider from "../../provider/ModalProvider"
export default function Modal(props) {
    const { showModal = false, setShowModal, disableClose = false,
        title = "", description = "",
        showCancelOption = true, cancelLbl = "Cancelar",
        continueLbl = "OK",
        handler = () => { }
    } = props
    const [generate, setGenerate] = useState(false)
    const [showBack, setShowBack] = useState(false)
    const [showContent, setShowContent] = useState(false)
    const [action, setAction] = useState(undefined)
    useEffect(() => {
        document.body.classList.remove('modal-open');
        if (showModal){
            setGenerate(showModal)
            document.body.classList.add('modal-open');
        }
        if (!showModal) {
            document.body.classList.remove('modal-open');
            setTimeout(() => {
                setGenerate(false)
            }, 300);
        }
        const timerBack = setTimeout(() => {
            setShowBack(showModal)
        }, 100);
        const timer = setTimeout(() => {
            setShowContent(showModal)
        }, 300);

        return () => {
            clearTimeout(timer)
            clearTimeout(timerBack)
        };
    }, [showModal])
    return (
        <ModalProvider setShowModal={setShowModal}>
            {
                generate &&
                createPortal(
                    <div className={`z-[99] fixed inset-0 w-full h-full bg-black bg-opacity-50 transition-all overflow-x-hidden overflow-y-auto ${showBack ? 'opacity-1' : 'opacity-0'}`}>
                        <div className={`relative rouded-lg overflow-hidden bg-white transition-all my-4 mx-auto ${showContent ? 'opacity-1 scale-100' : 'opacity-0 scale-90'} fixed w-full md:w-[28rem] 2xl:w-[32rem] max-w-full`}>
                            <div className="rounded-lg flex flex-col">
                                <div className="w-full p-8 h-fit bg-indigo-700">
                                    {
                                        !disableClose &&
                                        <div className="pt-4 pr-4 right-0 absolute top-0">
                                            <button
                                                onClick={() => setShowModal(false)}
                                                className="transition-all text-white  hover:text-indigo-400 rounded">
                                                <FaTimes className="w-5 h-5" />
                                            </button>
                                        </div>
                                    }
                                    <div className="mt-4 mr-4 text-white text-center">
                                        <p className="text-lg font-bold">{title}</p>
                                        {description && <p className="text-sm mt-2">{description}</p>}
                                    </div>
                                </div>
                                <div className="bg-white --h-full w-full p-8 --overflow-hidden">
                                    <div className="-h-full ---overflow-y-auto">
                                        {props.children}
                                    </div>
                                </div>
                                {
                                    false &&
                                    <div className="p-8 flex justify-between gap-8 md:gap-16">
                                        {
                                            showCancelOption &&
                                            <button
                                                onClick={() => setShowModal(false)}
                                                className="transition-all w-full p-2 rounded-md bg-slate-200 font-bold hover:bg-slate-100">
                                                {cancelLbl}
                                            </button>
                                        }
                                        <button
                                            className="transition-all w-full p-2 rounded-md border bg-indigo-700 text-white font-bold hover:bg-indigo-500"
                                        >
                                            {continueLbl}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>,
                    document.body
                )
            }
        </ModalProvider>

    )
}
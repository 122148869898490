import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getAreas = () => {
  return axios.get(`${API_URL}/areas`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createArea = (data) => {
  return axios.post(`${API_URL}/areas`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getArea = (id) => {
  return axios.get(`${API_URL}/areas/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateArea = (data) => {
  return axios.put(`${API_URL}/areas/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getAreas,
  createArea,
  getArea,
  updateArea
}

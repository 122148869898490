import { useEffect, useState } from "react"
import { MdArrowRightAlt, MdInfo, MdChevronRight } from "react-icons/md"
import Modal from "../../../../Modal/Modal"
import { useForm, Controller } from "react-hook-form"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import CustomInput from "../../CustomInput"
import EmployeesService from "../../../../../services/employees.service"
import { toast } from "react-hot-toast"
export default function EmployeeStatusNotice(props) {
    const { employeeStatusNotice, employee } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [statusNoticeBg, setStatusNoticeBg] = useState("bg-slate-500")
    const [showModal, setShowModal] = useState(false)
    const [statusTypesSelector, setStatusTypesSelector] = useState([])
    const [loading, setLoading] = useState(false)
    const [statusNotice, setStatusNotice] = useState(null)
    const [employeeStatus, setEmployeeStatus] = useState(null)
    const statusNoticeCatalog = {
        "default": "bg-slate-500",
        "success": "bg-emerald-500",
        "caution": "bg-amber-500",
        "warning": "bg-red-500",
        "danger": "bg-black",
        "onhold": "bg-cyan-500"
    }

    useEffect(() => {
        if (employeeStatusNotice) {
            manageStatusNotice(employeeStatusNotice)
        }
    }, [employeeStatusNotice])

    const manageStatusNotice = (data) =>{
        const { status = "default", statusOptions = [], employeeStatus = null } = data
        setStatusNotice(data)
        setStatusNoticeBg(statusNoticeCatalog[status] ? statusNoticeCatalog[status] : statusNoticeCatalog["default"])
        setStatusTypesSelector(statusOptions.map((item) => ({ value: item.id, label: item.name })))
        setEmployeeStatus(employeeStatus)
        setFormData(employeeStatus)
    }


    const setFormData = (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "statusType": data?.statusTypeId ?? "",
            "reason": data?.reason ?? "",
        })
    }

    const handleOnChangeStatusType = (e) => {
        setValue("statusType", e.target.value)
        setValue("reason", "")
    }

    const handleCancel = () => {
        setShowModal(false)
        setFormData(employeeStatus)
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeStatusNotice(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    manageStatusNotice(response.data.data.employeeStatusNotice)
                    handleCancel()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return (
        <>
            <div className={`${statusNoticeBg} drop-shadow-md py-4 px-8 text-white flex justify-between items-center`}>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <p>Estatus del empleado</p>
                        <MdArrowRightAlt className="w-6 h-6" />
                        <p className="font-bold">{statusNotice?.leyend ?? "N/E"}</p>
                    </div>
                    {
                        statusNotice?.noticeLeyend &&
                        <div className="w-full">
                            <p className="text-sm flex items-center gap-2">
                                <MdInfo /> {statusNotice.noticeLeyend}
                            </p>
                        </div>
                    }
                </div>
                <div>
                    <button
                        onClick={(e) => setShowModal(true)}
                        className="bg-white hover:bg-slate-200 text-slate-500 rounded-full drop-shadow-md">
                        <MdChevronRight className="w-8 h-8" />
                    </button>
                </div>
            </div>
            <Modal
                title={"Estatus del empleado"}
                showModal={showModal}
                setShowModal={setShowModal}
            >
                <form onSubmit={handleSubmit(submit)}>
                    <div className="relative flex flex-col gap-4 pb-4">
                        <div className="w-full">
                            <div>
                                <Controller
                                    control={control}
                                    name="statusType"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <CustomInput
                                            eKey="statusType"
                                            type="select"
                                            options={statusTypesSelector}
                                            value={value}
                                            active={true}
                                            label="Estatus"
                                            onChange={handleOnChangeStatusType}
                                        />
                                    )}
                                />
                                {errors.statusType && <FieldRequiredError />}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="" className="block mb-2 text-xs">Comentarios (opcional)</label>
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="2"
                                autoComplete="off"
                                defaultValue={""}
                                {...register("reason")}
                                className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-2 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1"
                            ></textarea>
                        </div>
                        <FormDefaultActions
                            activateForm={true}
                            handleCancel={handleCancel}
                            loading={loading}
                        />
                    </div>
                </form>
            </Modal>
        </>
    )
}
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../services/auth.service";
import verifyAccess from "./verifyAccess";
export default function OutletPermission({ allowedRoles = [] }) {
    if (AuthService.isLoggedIn()) {
        const content = (
            verifyAccess({allowedRoles })
                ? <Outlet />
                : <Navigate to="/" />
        )
        return content
    }
    return <Navigate to="/login" />
}

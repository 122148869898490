import AuthService from "../services/auth.service"
import UseAuth from "./useAuth"
export default function verifyAccess(props) {
    const { allowedRoles = [] } = props
    const isLoggedIn = AuthService.isLoggedIn()
    const { access, isAdmin } = UseAuth()
    if (isLoggedIn && allowedRoles) {
        return access.some(role => (allowedRoles.includes(role.code))) || isAdmin
    }
    return false
}
import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import { toast } from "react-hot-toast"
export default function ImssCover(props) {
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [employeeImssCover, setEmployeeImssCover] = useState(null)
    const [isNew, setIsNew] = useState(false)
    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeeImssCover()
        }
    }, [employee, active])

    const getEmployeeImssCover = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeeImssCover(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }

    const formatData = (data) => {
        const { imss } = data
        setEmployeeImssCover(imss)
        setFormData(imss)
    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            setFormData(employeeImssCover)
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = async (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "registroPatronal": data?.registroPatronal ?? "",
            "nss": data?.nss ?? "",
            "umf": data?.umf ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeImssCover(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployeeImssCover()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }
    return (
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        IMSS
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} showNew={false} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="registroPatronal"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="registroPatronal"
                                                                active={activateForm}
                                                                label="Registro patronal"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="nss"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="nss"
                                                                active={activateForm}
                                                                label="NSS"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="umf"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="umf"
                                                                active={activateForm}
                                                                label="UMF"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
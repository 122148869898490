import { useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import {
  MdDashboard,
  MdPeople,
  MdAutoAwesome,
  MdBusiness,
  MdExitToApp,
} from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa";
import { useState, useContext } from "react";
import AppContext from "../../../context/AppContext";
import MenuItem from "./MenuItem";
import RequirePermission from "../../../auth/RequirePermission";
export default function Navigation(props) {
  const { handleShowNav } = props;
  const { navLocation } = useContext(AppContext);
  const [optionSelected, setOptionSelected] = useState("");
  const [enableOption, setEnableOption] = useState(false);
  let location = useLocation();
  const logOut = () => {
    AuthService.logout();
  };
  const isActive = (target) => {
    let arrayUrl = location.pathname.replace("/", "").split("/");
    let path = arrayUrl[0];
    return target === path;
  };
  const handleOptionSelected = (action, option) => {
    setEnableOption(action);
    setOptionSelected(option);
    if (optionSelected !== option && optionSelected !== "") {
      setEnableOption(true);
    }
  };
  const handleCloseMenu = () => {
    handleShowNav();
  };
  const menuOptions = {
    dashboard: {
      label: "Dashboard",
      href: "/",
      icon: <MdDashboard className="w-4 h-4" />,
      requirePermission: false,
      allowedRoles: [],
    },
    empleados: {
      label: "Empleados",
      icon: <MdPeople className="w-4 h-4" />,
      requirePermission: true,
      allowedRoles: ["rrhh_employees"],
      options: [
        {
          label: "Personal",
          href: "/empleados",
        },
        {
          label: "Departamentos",
          href: "/empleados/departamentos",
        },
        {
          label: "Puestos",
          href: "/empleados/puestos",
        },
      ],
    },
    talento: {
      label: "Talento",
      icon: <MdAutoAwesome className="w-4 h-4" />,
      requirePermission: true,
      allowedRoles: ["rrhh_talent"],
      options: [
        {
          label: "Vacantes",
          href: "/talento/vacantes",
        },
        {
          label: "Aplicantes",
          href: "/talento/aplicantes",
        },
      ],
    },
    turnos: {
      label: "Turnos",
      href: "/turnos",
      icon: <FaBusinessTime className="w-4 h-4" />,
      requirePermission: true,
      allowedRoles: ["rrhh_emshifts"],
    },
    empresa: {
      label: "Empresa",
      icon: <MdBusiness className="w-4 h-4" />,
      options: [
        {
          label: "Entidades Legales",
          href: "/empresa/locaciones",
        },
        {
          label: "Propiedades",
          href: "/empresa/propiedades",
        },
        {
          label: "Oficinas",
          href: "/empresa/propiedades",
        },
      ],
    },
  };
  return (
    <nav className="sticky top-0 h-[100vh] --border-r-1 --border-slate-200 --bg-dark --text-white w-[14rem] bg-white shadow-lg">
      <div className="w-full h-[5rem] flex justify-center items-center pt-8 relative p-4 border-b border-r border-light">
        <a to="/">
          <img
            src="/files/logo-hotelesmia.png"
            alt=""
            width={80}
            height={71}
            className=""
          />
        </a>
      </div>
      <ul className="p-4 flex flex-col gap-2">
        <MenuItem
          navLocation={navLocation}
          optionKey={"dashboard"}
          {...menuOptions["dashboard"]}
          handleOptionSelected={handleOptionSelected}
          enableOption={enableOption}
          optionSelected={optionSelected}
        />
        <MenuItem
          navLocation={navLocation}
          optionKey={"empleados"}
          {...menuOptions["empleados"]}
          handleOptionSelected={handleOptionSelected}
          enableOption={enableOption}
          optionSelected={optionSelected}
        />
        <MenuItem
          navLocation={navLocation}
          optionKey={"talento"}
          {...menuOptions["talento"]}
          handleOptionSelected={handleOptionSelected}
          enableOption={enableOption}
          optionSelected={optionSelected}
        />
        <MenuItem
          navLocation={navLocation}
          optionKey={"turnos"}
          {...menuOptions["turnos"]}
          handleOptionSelected={handleOptionSelected}
          enableOption={enableOption}
          optionSelected={optionSelected}
        />
      </ul>
      <RequirePermission
        requirePermission={true}
        allowedRoles={["rrhh_business"]}
      >
        <ul className="p-4 border-t border-b">
          <MenuItem
            navLocation={navLocation}
            optionKey={"empresa"}
            {...menuOptions["empresa"]}
            handleOptionSelected={handleOptionSelected}
            enableOption={enableOption}
            optionSelected={optionSelected}
          />
        </ul>
      </RequirePermission>
      <ul className="p-4">
        <li>
          <a
            href="/login"
            onClick={logOut}
            className="w-full text-[14px] font-bold flex rounded-md hover:bg-red-800 hover:text-white"
          >
            <span className="p-2 w-full flex items-center justify-between">
              Cerrar Sesión <MdExitToApp className="w-4 h-4" />
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

import { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import LoadingCard from "../../../../Utils/LoadingCard"
import CustomInput from "../../CustomInput"
import FormOptions from "../../../FormOptions"
import { toast } from "react-hot-toast"
import FieldRequiredError from "../../../../Utils/FieldRequiredError"
import FormDefaultActions from "../../../../Utils/FormDefaultActions"
import EmployeesService from "../../../../../services/employees.service"
import dateFormat from "../../../../../custom_hooks/dateFormat"
import { MdNavigateNext } from "react-icons/md"
export default function Contracts(props){
    const { employee, active } = props
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, control } = useForm()
    const [loadingComponent, setLoadingComponent] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activateForm, setActivateForm] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [employeeContract, setEmployeeContract] = useState(null)
    const [contractTypesSelector, setContractTypesSelector] = useState([])
    const [statusSelector, setStatusSelector] = useState([{'value': '0', 'label': 'Inactivo'},{'value': '1', 'label': 'Activo'}])

    useEffect(() => {
        if (employee && active && loadingComponent) {
            getEmployeeContract()
        }
    }, [employee, active])

    const getEmployeeContract = () => {
        setLoadingComponent(true)
        EmployeesService.getEmployeeContract(employee)
            .then(response => {
                if (response.status === 200) {
                    formatData(response.data.data)
                    setLoadingComponent(false)
                }
            })
    }

    const formatData = (data) => {
        const { catalogs, employeeContract } = data
        setEmployeeContract(employeeContract)
        formatCatalogs(catalogs)
        setFormData(employeeContract)
    }

    const formatCatalogs = (catalogs) => {
        const { contractTypes } = catalogs
        setContractTypesSelector(contractTypes.map((item) => ({ value: item.id, label: `${item.code} ${item.name}` })))

    }

    const handleFormOptionAction = (props = {}) => {
        const { action = "edit", activateForm = true } = props
        if (action === "edit" || action === "reset") {
            setFormData(employeeContract)
        }
        else {
            setIsNew(true)
            setFormData()
        }
        activateForm ? setActivateForm(true) : setActivateForm(false)
    }

    const setFormData = async (data = {}) => {
        reset({
            "entityId": data?.id ?? 0,
            "contractType": data?.contractTypeId ?? "",
            "status": (data?.status ? "1" : "0"  ) ?? ""
        })
    }

    const handleCancel = () => {
        handleFormOptionAction({ action: "reset", activateForm: false })
    }

    const submit = (data) => {
        setLoading(true)
        EmployeesService.manageEmployeeContracts(employee, data)
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    setActivateForm(false)
                    getEmployeeContract()
                    toast.success("Se actualizó la información correctamente")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoading(false)
            })
    }

    return(
        <div>
            {
                !loadingComponent ?
                    <div className="bg-white border border-slate-200 rounded-md drop-shadow-md">
                        <div className="p-8">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <p className="font-medium block">
                                        Contrato actual
                                    </p>
                                    <div className="w-8 h-0.5 bg-indigo-700"></div>
                                </div>
                                <FormOptions handleAction={handleFormOptionAction} />
                            </div>
                            <div className="mt-8">
                                <form onSubmit={handleSubmit(submit)}>
                                    <input
                                        hidden
                                        {...register("entityId")}
                                    />
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-8">
                                            <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="contractType"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="contractType"
                                                                type="select"
                                                                options={contractTypesSelector}
                                                                active={activateForm}
                                                                label="Tipo de contrato"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name="status"
                                                        rules={{ required: true }}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <CustomInput
                                                                eKey="status"
                                                                type="select"
                                                                options={statusSelector}
                                                                active={activateForm}
                                                                label="Estatus"
                                                                onChange={onChange}
                                                                value={value ?? ""}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <FormDefaultActions
                                                activateForm={activateForm}
                                                handleCancel={handleCancel}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="hidden mt-8 flex justify-center border-t border-slate-200">
                                <button className="flex justify-center items-center pt-8 text-sm text-gray-400 hover:text-indigo-700">
                                    Ver historial de contratos <MdNavigateNext className="w-5 h-5"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <LoadingCard />
            }
        </div>
    )
}
import { useState, useEffect } from "react";
import LoadingCard from "../../Utils/LoadingCard";
import LoadingItems from "../../Utils/LoadingItems";
import ShiftWorkScheduleService from "../../../services/shiftworkschedule.service";
import { MdCalendarMonth, MdArrowRightAlt, MdEdit, MdEditCalendar, MdOutlineKeyboardArrowDown} from "react-icons/md";
import dateFormat from "../../../custom_hooks/dateFormat";
import DayChip from "./DayChip";
import Modal from "../../Modal/Modal";
import ShiftWorkScheduleForm from "./ShiftWorkScheduleForm";
export default function EmployeeShiftWorkItem(props) {
  const { employee } = props;
  const [showModal, setShowModal] = useState(false);
  const [showEmployeeSchedule, setShowEmployeeSchedule] = useState(false);
  const [loadingEmployeeShiftWorks, setLoadingEmployeeShiftWorks] =
    useState(false);
  const [shiftWorkSchedules, setShiftWorkSchedules] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [shiftWorkSelected, setShiftWorkSelected] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    if (reloadData) getEmployeeSchedulesAssigned(employeeSelected.id);
  }, [reloadData]);

  const handleEmployeeShiftWorks = (employee) => {
    
    if(!showEmployeeSchedule){
      setEmployeeSelected(employee);   
      getEmployeeSchedulesAssigned(employee.id);
    }
    setShowEmployeeSchedule(!showEmployeeSchedule);
    
  };

  const getEmployeeSchedulesAssigned = (employeeID) => {
    setLoadingEmployeeShiftWorks(true);
    ShiftWorkScheduleService.getEmployeeSchedulesAssigned(employeeID).then(
      (response) => {
        if (response.status === 200) {
          setShiftWorkSchedules(response.data.shiftWorkSchedules);
          setLoadingEmployeeShiftWorks(false);
        }
      }
    );
  };

  const handleScheduleDetail = (workShiftID) => {
    setReloadData(false)
    setShiftWorkSelected(workShiftID);
    setShowModal(true);
  };

  const handleNewShiftWork = () => {
    setReloadData(false)
    setIsNew(true);
    setShowModal(true);
  };

  const handleShowModal = (show) => {
    setShowModal(show);
    setIsNew(false);
    setShiftWorkSelected(null);
  };

  return (
    <>
      <div className="border bg-white drop-shadow-lg rounded-lg ">
        <div className="p-4 flex justify-between items-center">
          <div className="flex flex-col">
          <span className="text-sm font-bold">{`${employee.name} ${employee.lastName}`}</span>
          <span className="text-[10px]">{employee.positions[0].name}</span>
          </div>
          <div className="">
            <button
              onClick={() => handleEmployeeShiftWorks(employee)}
              className=""
            >
              <MdOutlineKeyboardArrowDown className={`h-8 w-8 transition-all ${showEmployeeSchedule ? ' rotate-180' : ''}`} />
            </button>
          </div>
        </div>
        {showEmployeeSchedule && (
          <div className="px-8 py-4 border-t">
            {loadingEmployeeShiftWorks ? (
              <LoadingItems />
            ) : (
              <>
                <div className="flex justify-end pb-4">
                  <button
                    onClick={() => handleNewShiftWork()}
                    className="bg-indigo-700 text-white py-1 px-2 rounded-lg text-sm font-bold"
                  >
                    Nuevo horario
                  </button>
                </div>
                {shiftWorkSchedules.map((schedule, indexSchedule) => {
                  return (
                    <div
                      key={`employee-schedule-${indexSchedule}`}
                      className="border-b py-2 flex flex-row gap-4 relative"
                    >
                      <div>
                        <span className="text-xs font-bold">
                          {schedule.name}
                        </span>
                        <div className="flex gap-1 items-center">
                          <MdCalendarMonth />
                          <span className="text-xs">
                            {dateFormat(schedule.fromDate)}
                          </span>
                          <MdArrowRightAlt />
                          <span className="text-xs">
                            {dateFormat(schedule.toDate)}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                        {schedule.weekDays.map((day, dayIndex) => {
                          return (
                            <DayChip key={`day-chip-${dayIndex}`} day={day} />
                          );
                        })}
                      </div>
                      <div className=" absolute right-0">
                        <button
                          onClick={() => handleScheduleDetail(schedule)}
                          className="p-2 hover:bg-indigo-800 hover:text-white rounded-lg"
                        >
                          <MdEditCalendar />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        title={"Horario de empleado"}
        showModal={showModal}
        setShowModal={handleShowModal}
      >
        <ShiftWorkScheduleForm
          isNew={isNew}
          shiftWorkSelected={shiftWorkSelected}
          employeeSelected={employeeSelected}
          setReloadData={setReloadData}
        />
      </Modal>
    </>
  );
}

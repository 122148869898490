import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getProperties = () => {
  return axios.get(`${API_URL}/properties`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createProperty = (data) => {
  return axios.post(`${API_URL}/properties`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getProperty = (id) => {
  return axios.get(`${API_URL}/properties/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateProperty = (data) => {
  return axios.put(`${API_URL}/properties/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getProperties,
  createProperty,
  getProperty,
  updateProperty
}

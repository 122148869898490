import { useState, useEffect } from "react"
import DirectoryMasterFiles from "../../../FileManager/DirectoryMasterFiles"
export default function EmployeeFiles(props) {
    const { employee, active } = props
    const [loadingComponent, setLoadingComponent] = useState(true)
    useEffect(() => {
        if (employee && active) {
            setLoadingComponent(false)
        }
    }, [employee, active])
    return (
        <>
            {
                !loadingComponent &&
                    <DirectoryMasterFiles directory={employee} />
            }
        </>
    )
}
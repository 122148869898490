export default function ScheduleDayItem(props){
    const { day } = props
    return(
        <div className="flex flex-row gap-4 items-center">
            <span className="text-sm font-medium">{day.name}</span>
            {
                props.children
            }
        </div>
    )
}
import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getAssignedEmployees = (params) => {
    return axios.get(`${API_URL}/assigned-employees`, { params })
      .then(response => {
        return response
      })
      .catch(error => {
        return error.response
      })
  }

  export default {
    getAssignedEmployees
  }
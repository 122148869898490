import { Navigate, Outlet } from "react-router-dom"
import AuthService from "../../services/auth.service"
import AppProvider from "../../provider/AppProvider"
export default function ProtectedRoute(props){  
    if(!AuthService.isLoggedIn()){
        return <Navigate to="/login" />
    }  
    return (
        <AppProvider>
            <Outlet />
        </AppProvider>

    )
}
import { MdHelp } from "react-icons/md"
import { FaFileCsv, FaFileWord, FaFileExcel, FaFilePdf, FaFilePowerpoint } from "react-icons/fa"
export default function FileIcon(props) {
    const { fileType = "default", size = "sm" } = props
    const Icon = () => {
        let iconSize = { sm: 'w-8 h-8', md: 'w-14 h-14', lg: 'w-20 h-20'}
        //<MdHelp className="w-20 h-20" />
        let objIcon = {
            'default' : <MdHelp className={iconSize[size]} />,
            'null' : <MdHelp className={iconSize[size]} />,
            'pdf': <FaFilePdf className={iconSize[size]} />,
            'csv': <FaFileCsv className={iconSize[size]} />,
            'docx': <FaFileWord className={iconSize[size]} />,
            'xlsx': <FaFileExcel className={iconSize[size]} />,
            'FaFilePowerpoint': <FaFilePowerpoint className={iconSize[size]} />
        }
        let icon = objIcon[fileType] //<MdHelp className={iconSize[size]} />
        return icon
    }

    return (
        <Icon />
    )
}
import { useState, useEffect } from "react"
import EmployeeFiles from "./EmployeeFiles"
export default function DocumentsTab(props){
    const { optionKey, enableTab, tabSelected } = props
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(tabSelected === optionKey)
    }, [enableTab, tabSelected])
    const objProps = {...props, ...{ active }}
    return(
        <div className="flex flex-col gap-8">
            <EmployeeFiles {...objProps} />
        </div>
    )
}
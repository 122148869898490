import {
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import Dashboard from "./Dashboard"
import Areas from "./Areas/Areas"
import AreaDetail from "./Areas/AreaDetail"
import Properties from "./Properties/Properties"
import Jobs from "./Jobs/Jobs"
import JobDetail from "./Jobs/JobDetail"
import Applicants from "./Applicants/Applicants"
import Employees from "./Empleados/Employees"
import EmployeeDetail from "./Empleados/EmployeeDetail/EmployeeDetail"
import Departments from "./Empleados/Departments"
import ShiftWorks from "./ShiftWorks/ShiftWorks"
import ProtectedRoute from "../Auth/ProtectedRoute"
import OutletPermission from "../../auth/OutletPermission"
export default function Index() {
    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/areas" element={<Areas />} />
                    <Route path="/areas/create" element={<AreaDetail />} />
                    <Route path="/areas/:id" element={<AreaDetail />} />                    

                    <Route path="/empleados" element={<Employees />} />   
                    <Route path="/empleados/:uuid" element={<EmployeeDetail />} /> 
                    <Route path="/empleados/departamentos" element={<Departments />} />

                    <Route path="/talento/vacantes" element={<Jobs />} />
                    <Route path="/talento/vacantes/crear" element={<JobDetail />} />
                    <Route path="/talento/vacantes/:id" element={<JobDetail />} />
                    <Route path="/talento/aplicantes" element={<Applicants />} /> 
                    
                    <Route element={<OutletPermission allowedRoles={['rrhh_emshifts']} />}>
                        <Route path="/turnos" element={<ShiftWorks />} />
                    </Route>                    
                    <Route path="/empresa/propiedades" element={<Properties />} />                                               
                </Route>
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}
import { useState, useEffect } from "react"

export default function TabAction(props) {
    const { handleTabSelected, optionKey, tabSelected, label } = props
    const [isActive, setIsActive] = useState(false)
    useEffect(() => {
        (tabSelected === optionKey) ? setIsActive(true) : setIsActive(false)
    }, [tabSelected])
    return (
        <button
            onClick={() => handleTabSelected(optionKey)}
            className={`${isActive ? 'bg-indigo-500' : '' } hover:bg-indigo-500 p-2 rounded-md font-bold text-sm`}>
            {label}
        </button>
    )
}